import React, { useState } from "react";
import axios from "axios";

import "./Contact.css";

import { sendFeedback } from "../../../firebase";
import Navbar from "../../Navigation/Navbar";
import star from "../Map/star.svg"

export const Contact = () => {
  // variable to read the state - and a function to write the state, with an initial value in the shape of the data e.g.[], or "" etc
  const [feedbackType, setfeedbackType] = useState("");
  const [lastName, setLastName] = useState("");
  const [userTelephone, setUserTelephone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userTextArea, setUserTextarea] = useState("");



  function updatePreview(){
    const preview = document.getElementById("preview-img");
    const file = document.getElementById('Contact__form-fileInput').files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        console.log("READER LOAD");
        // convert image file to base64 string
        preview.src = reader.result
        console.log(reader.result)
      },
      false,
    );

    if (file) {
     reader.readAsDataURL(file)
    }
    
  }
  function submitfeedback(){
     


      sendFeedback(document.getElementsByClassName("select-star").length, feedbackType,userTextArea,document.getElementById("preview-img").src)
      setfeedbackType("")
      setUserTextarea("")
      document.getElementById("preview-img").src = ""
  }


  const n = 5;

  function setStar(Num){
    // alert(Num)


    var slides = document.getElementsByClassName("star-review");

    for (var i = 0; i < slides.length; i++) {

      slides[i].classList.remove("select-star");
    }

    for (var i = 0; i < slides.length; i++) {

        slides[i].classList.add("select-star");
        if (Num == i) break;
    }
  }

  return (
    <div id="Contact">
      <div className="main__body-contact">
        <header className="main__contact-header">
     
        </header>

        <div className="Contact__Form-body" name="Contact">
              
          <fieldset>
     
            <div id="Contact__form">
            <h2>Contact</h2>
            <br/>  <br/>
            <label class="Contact__form-feedbackType">Please rate your experience</label>
            <br/>  <br/>
                    <rating-button>  {[...Array(n)].map((e, i) => <img class="star-review" src={star} onClick={()=>{setStar(i)}} ></img>)}</rating-button>
                    {/* <rating-options><rating-option onClick={hideReview}>Cancel</rating-option ><rating-option onClick={saveReview}>Select</rating-option></rating-options> */}
  
                    <br/>  <br/>
              <label class="Contact__form-feedbackType">If you had an issue please select it's category</label>
              <br/>
              <br/>
              <select
                type="option"
                name="feedbackType"
                id="feedbackType"
                // controlled input onChange - circular store the input value in state when user types a letter each letter is saved , then you feed it back to input
                value={feedbackType}
                onChange={(event) => setfeedbackType(event.target.value)}
                className="form-control"
                placeholder="First name"
                required
              >

                <option value="Ui">User Interface</option>
                <option value="Performance">Performance</option>
                <option value="Useability">Useability</option>
                <option value="Other">Other</option>
              </select>
              <br/>
              <br/>

              <label class="Contact__form-feedbackType">If applicable attatch a screenshot of your listed issue </label>
              <br/>
              <br/>

          <input type="file" id="Contact__form-fileInput" onChange={updatePreview} accept="image/png, image/jpeg"/> 
          <img id="preview-img"></img>
       

              <label class="Contact__form-feedbackType">Please describe the issue further </label>
                <textarea
                  type="textArea"
                  id="comments"
                  value={userTextArea}
                  onChange={(event) => setUserTextarea(event.target.value)}
                className="input-textarea"
                name="comment"
                placeholder="Your comments here..."
                required
              ></textarea>

           <button
                type="submit"
                id="Contact__form-submit-Btn"
                className="submit-button"
                onClick={submitfeedback}    
              >
              
                Submit
              </button>
            </div>
          </fieldset>
        </div>
      </div>
      <Navbar></Navbar>
    </div>
  );
};

//Helper function to connect to the user contact input in its own function
