import * as React from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { Link } from "react-router-dom";
// import mapboxgl from "mapbox-gl";
import Map, {
  Marker,
  GeolocateControl,
  NavigationControl,
  Popup,  
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
// import { THREE } from "aframe";

// import * as ArJS from "ar.js"
// import * as aframe from "aframe/"
// import GoringHall_01 from "../../img/GoringHall_01.png";
import "./MapData.css";
import { locationData,previewData } from "./locationData";
import { navigatePage,userRating } from "../../../firebase";

import { getGyro } from "../Ar/AR";
import star from "../Map/star.svg"







// import { getLCP } from "web-vitals";
// import "https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar-nft.js";


// let tracking =  require("./testTrack.fset3").default;
// let tracking1 =  require("./testTrack.fset").default;
// let tracking2 =  require("./testTrack.iset").default;

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiYXJoaXZlIiwiYSI6ImNsbWhwY3hpYzAwbHczcG5ybXI3cjVvbzUifQ.IQwcaQagnppIG-SwxZbekQ";



  var cameraEl;

  function myFunction() {
    let camera =  document.querySelector("[camera]")
            
  

    alert("CAMERA ROT |  " + JSON.stringify(cameraEl.getAttribute('rotation')) + " | " + JSON.stringify(camera.getAttribute('rotation')))
  }
            

  const clamp = (num,min,max) => Math.min(Math.max(num,min),max)
  const AFRAME = window.AFRAME;
  
  let prevRot = ""
  let loadRot = false
  let markerFound = false

  let foundRotation = {x:0,y:0}




  let toggled = false
  function focusInfo(){
    let locPopUp = document.getElementById("locPopUp")

    enableTransition(300);

    if (!toggled) {
      locPopUp.classList.add("focus-pop-up")
    }
    else{
      locPopUp.classList.remove("focus-pop-up")
    }
    toggled = !toggled;
  
  }
  function unfocusInfo(){
    if (toggled){
      focusInfo();
    }
  }

  function enableTransition(num){
    let locPopUp = document.getElementById("locPopUp")

    locPopUp.classList.add("focusTransition")

    setTimeout(function(){
      locPopUp.classList.remove("focusTransition")
      }, num);
  }

  function setImagePreview(showing){
    
    let prevImage = document.getElementById("preview-Image")
    if (showing){

      prevImage.classList.remove("previewImageFound")
    }else{
      prevImage.classList.add("previewImageFound")
    }

  }


 
  AFRAME.registerComponent('locationnft', {
      init: function () {

          // alert("REG COMP");
          var marker = this.el;

         


          // document.querySelector("#arpopup").setAttribute('gps-entity-place', {
          //   latitude: 0,
          //   longitude: 0
          //  });
          //  document.querySelector("#arpopup").components["gps-entity-place"]._updatePosition();

          
         
          marker.addEventListener('markerFound', function () {

            if (markerFound) return
            cameraEl = this.el.sceneEl.camera.el;

            
            enableTransition(300);
            let locPopUp = document.getElementById("locPopUp")
            locPopUp.classList.remove("hidePopUp")

            setImagePreview(false)

            foundRotation = cameraEl.getAttribute('rotation');
            loadRot = true
            markerFound = true;


            var entity = this.el;
        
            entity.parentNode.removeChild(entity);
            
            // marker.remove()
            

       
        
            // document.querySelector("a-scene").addEventListener("click", myFunction);


            // cameraEl.setAttribute('position',{x:0,y:0,z:0});

           
            
            // cameraEl.components['look-controls'].pitchObject.rotation.set(0,0,0);
            // cameraEl.components['look-controls'].yawObject.rotation.set(0,0,0);




            // let modelTrack = document.getElementById("trackingModel").object3D;
            // console.log(JSON.stringify(modelTrack.position) + " | -> | " + JSON.stringify(modelTrack.rotation))
            // var popup = document.querySelector("#arpopup")

            // let pos = modelTrack.position
            // let rot = modelTrack.rotation
            // popup.setAttribute("visible",true);   
            // popup.setAttribute('position',pos)
            // popup.setAttribute('rotation',rot);

            let rotate =  cameraEl.getAttribute('rotation');
            // // cameraEl.setAttribute('rotation',{x:-rotate.x,y:-rotate.y,z:-rotate.z});

            // alert("ROT AFTER " +  JSON.stringify(cameraEl.getAttribute('rotation')))

            var popup = document.querySelector("#arpopup")
            popup.setAttribute("visible",true);    
            // console.log("PITCH")
            // let pitch = cameraEl.components['look-controls'].pitchObject.rotation.x
            // let yaw = cameraEl.components['look-controls'].yawObject.rotation.y
            // console.log(cameraEl.components['look-controls'].yawObject.rotation)
            // var popup = document.querySelector("#arpopup")
            // popup.setAttribute("visible",true);    
            // popup.setAttribute('position',{x:0,y:0,z:0});

            // console.log(pitch)
            // console.log(yaw)
            // let x = Math.sin(yaw) * Math.cos(pitch)
            // let y = Math.sin(pitch) 
            // let z = Math.cos(yaw) * Math.cos(pitch)

            // let dist = 20;
            // let newCoord = { x: x*dist*-1,y: y*dist,z: z*dist*-1}
            // console.log("NEW COORD ")
            // console.log(newCoord)
            // popup.setAttribute('position',newCoord)
            // popup.setAttribute('rotation',{x:rotate.x,y:rotate.y,z:rotate.z});

            // const worldpos = new THREE.Vector3();
            // popup.getWorldPosition(worldpos)

            // console.log(" WORLD POS ")
            // console.log(worldpos)
            
       // console.log(camera.object3D.rotation)
              // alert("TRACK FOUND")
          }.bind(this));
          // camera.addEventListener('gps-camera-update-positon', function () {

         
          //     // alert("TRACK FOUND")
          // }.bind(this));

    marker.addEventListener('markerLost', function() {
        // this.sound.pause();
        // this.sound.currentTime = 0;
    }.bind(this));
        },
      // tick:function(){
      //   console.log("hello")
      // }
    });


    function hideReview(){
      document.getElementById("ratingBack").classList.add("hideReviewBack")
  }
  function saveReview(){
    let starNum = document.getElementsByClassName("select-star").length;
    userRating(starNum)
    hideReview()
  }
  function showReview(){
    document.getElementById("ratingBack").classList.remove("hideReviewBack")
  }

  let shownReview = false;

  const parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});


  // setTimeout(function(){
  //   if (!shownReview){
  //     let cookies = parseCookie(document.cookie)
  //     // console.log("COOKIES: " , cookies)
  //     if (cookies["reviewShown"] != "true"){

  //         showReview()
  //         shownReview = true;
  //         cookies = parseCookie(document.cookie)
  //         // console.log("BEFORE COOKIE: " , cookies)
  //         document.cookie = "reviewShown= " + shownReview
  //         cookies = parseCookie(document.cookie)
  //         // console.log("AFTER COOKIE: " , cookies)
  //     }
   
  //   }
    
  // },1800000) // 30 minutes

export const MapData = () => {


  // const projectRootDirectory = require('project-root-directory')

  // console.log("ROOT "  + projectRootDirectory)


  const [showPopup, setShowPopup] = React.useState(false);
  // const [popupColor, setPopupColor] = React.useState("");


  const [isActive, setIsActive] = React.useState(false);

  // const [locationInfo, setLocationInfo] = React.useState({});
  const [vantageFound, setVantageFound] = React.useState(true);

  const [locationsAvailable, setLocationsAvailable] = React.useState([]);

  const data = locationData;


  var prevlocation = "";

  
  let locationAtInfo = { }


  var directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/cycling/-84.518641,39.134270;-84.512023,39.102779?geometries=geojson&access_token=pk.eyJ1IjoiYXJoaXZlIiwiYSI6ImNsbWhwY3hpYzAwbHczcG5ybXI3cjVvbzUifQ.IQwcaQagnppIG-SwxZbekQ`


  function htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

let list = {}

  let host = "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main"
  let flagPrefix = "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Flags"
  let flagSrc = {
    "jamaica" : "Jamaica.svg",
    "tobago" :  "Trinidad_Tobago.svg",
    "virgin islands" : "VirginIslands.svg",
    "british guiana": "BritishGuiana.svg",
    "st vincent": "StVincent.svg",
    "montserrat":"Montserrat.svg",
    "antigua":"Antigua.svg",
    "st kitts":"StKittsNevis.svg",
    "grenada":"Grenada.svg",
    "barbados":"Barbados.svg",
    "mauritius":"Mauritius.svg",
    "trinidad":"Trinidad_Tobago.svg",
    "dominica":"Dominica.svg"
  }
  let flagRatio={
    "british guiana" :"width:40px;height:20px"
  }
  let map;  
  let userPosition;
  React.useEffect(() => {
    // code to run after render goes here

      mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

      map = new mapboxgl.Map({
      container: 'mapcontainer',
      center: [-0.1466833563624732, 50.8150822833629], // starting position

      zoom: 12,
      pitch: 45,
      bearing: -10, // bearing in degrees
      accessToken:MAPBOX_ACCESS_TOKEN,
      style:"mapbox://styles/mapbox/navigation-night-v1",
      // style:{ width: "100%", height: "100%" }

     
      // initialViewState:{
      //   longitude: -0.16038,
      //   latitude: 50.898625,
      //   zoom: 8.5,
      //   pitch: 45,
      //   bearing: -10, // bearing in degrees
            // },
   
    });
    
    map.on('style.load', () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.getStyle().layers;
      const labelLayerId = layers.find(
      (layer) => layer.type === 'symbol' && layer.layout['text-field']
      ).id;
       
      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      map.addLayer(
      {
      'id': 'add-3d-buildings',
      'source': 'composite',
      'source-layer': 'building',
      'filter': ['==', 'extrude', 'true'],
      'type': 'fill-extrusion',
      'minzoom': 15,
      'paint': {
      'fill-extrusion-color': '#aaa',
       
      // Use an 'interpolate' expression to
      // add a smooth transition effect to
      // the buildings as the user zooms in.
      'fill-extrusion-height': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'height']
      ],
      'fill-extrusion-base': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'min_height']
      ],
      'fill-extrusion-opacity': 0.6
      }
      },
      labelLayerId
      );
    });

  
    

    let geolocate =   new mapboxgl.GeolocateControl({
      positionOptions: {
      enableHighAccuracy: true
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      showUserHeading: true,
      showUserLocation: true,
      fitBoundsOptions: {
        maxZoom: 20
    } 
      });
      
    map.on('load', function() {
        geolocate.trigger(); 
    });

    map.addControl(geolocate );

    geolocate.on('geolocate', (e)=>{
      locate(e);
      userPosition = [e.coords.longitude,e.coords.latitude];
    });


  
    
    data.map((pin) => {

        // const popup = new mapboxgl.Popup({ offset: 25, anchor:"bottom" }).setHTML(
        //   `
        //   "ID:" ${pin.id}

        //   <br/>
        //   Owner:  ${pin.FirstName_LastName_UK}
        //   <br />
        //    Number enslaved: ${pin.people_enslaved}
        //   <br />
        //    Compensation 1835:  ${pin.compensation}
        //   <br />
        //    Today's value:  +${pin.Money_today}
        //   `

        // );
    
          console.log()
          let foundSrc = ""
          let extraStyle = "";
          if (pin.Island != undefined){
          

          foundSrc = ""
          for (const [key, value] of Object.entries(flagSrc)) {
            if (pin.Island.toLowerCase().includes(key)){
              foundSrc = value;
              break;
            }
           
          }


       

          for (const [key, value] of Object.entries(flagRatio)) {
            if (pin.Island.toLowerCase().includes(key)){
              extraStyle = value;
              break;
            }
           
          }



          }

          
          let center = pin.FirstName_LastName_UK.toLowerCase().includes("test point") ? "<marker-text>📚</marker-text>" : "<marker-center></marker-center>"
          let markerIcon = foundSrc == "" ? center: `<img class="marker-flag" style="${extraStyle}" id="flag-${pin.Island}"src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Flags/${foundSrc}">` 
   
          

          console.log(markerIcon);
          let el = htmlToElement(`<div><marker-pointer id="marker-${pin.id}"></marker-pointer>${markerIcon}</div>`)
          el.className = 'marker';

          // make a marker for each feature and add to the map

          let thisCoord = [pin.longitude, pin.latitude];
          let newMarker = new mapboxgl.Marker(el,{
            color: "black",
            // anchor="top"
      
            // key:pin.id,
            anchor:"bottom"

          }).setLngLat(thisCoord).addTo(map);

          let datamissing = false;

          for (const [key, value] of Object.entries(pin)) {
            if (typeof value === 'string' && value.indexOf("No Data") != -1){
              datamissing = true;
              break;
            }
          }

          el.style.backgroundColor = datamissing ? (pin.FirstName_LastName_UK.toLowerCase().includes("test point") ? "rgb(102, 42, 255)" : "rgb(100,100,100)"): "white" ;

          // if (!pin.FirstName_LastName_UK.toLowerCase().includes("Brighton")){
          //   el.style.filter = "greyscale(100%)"
          // }
          el.addEventListener('click', () => { 

                  if (userPosition != null){
                        getRoute(userPosition,thisCoord)

                      
                  }
                  // el.style.backgroundColor = "red";
                  // list[pin.id] = pin;
                  // console.log(list)
          }
); 

    })
    map.on('move', () => {
      console.log(map.getCenter())

     });
     map.on()
  
 
  }, []); // 

  async function getRoute(start, end) {
    // make a directions request using cycling profile
    // an arbitrary start will always be the same
    // only the end or destination will change



    const routePoints = {
      start: start,
      end: end
    }

    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/cycling/${routePoints.start[0]},${routePoints.start[1]};${routePoints.end[0]},${routePoints.end[1]}?steps=true&geometries=geojson&access_token=${"pk.eyJ1IjoiYXJoaXZlIiwiYSI6ImNsbWhwY3hpYzAwbHczcG5ybXI3cjVvbzUifQ.IQwcaQagnppIG-SwxZbekQ"}`,
      { method: 'GET' }
    );
    const json = await query.json();
    const data = json.routes[0];
  
    const route = data.geometry.coordinates;
    console.log("ROUTE")
    console.log(json)
  const geojson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: route
    }
  };
  // if the route already exists on the map, we'll reset it using setData
  if (map.getSource('route')) {
    map.getSource('route').setData(geojson);
  }
    // otherwise, we'll make a new request
    else {
      map.addLayer({
        id: 'route',
        type: 'line',
        source: {
          type: 'geojson',
          data: geojson
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'white',
          'line-width': 5,
          'line-opacity': 1
        }
      });
    }
  }
  // React.useEffect(function(){
  //     getRoute();
  // },[])
 

  function setLocationInfo(info){
    locationAtInfo = info

    let prevImage = document.getElementById("preview-Image")
    prevImage.src = locationAtInfo.preview;
  }

  // const el = document.querySelector('#arjs-video');
  // el.classList.add("hidden");

  let showingAR = true;
//   function updateAR(value){
    
//     showingAR = value;
//     if (document.getElementById("arjs-video") != null){
//       if (showingAR){
//       document.getElementById("arjs-video").classList.add("show");
//       }
//       else{
//         document.getElementById("arjs-video").classList.remove("show");
//       }
//     }

//     if (document.getElementById("ar-overlay") != null){
//       if (showingAR){
//       document.getElementById("ar-overlay").classList.add("show");
//       }
//       else{
//         document.getElementById("ar-overlay").classList.remove("show");
//       }
//     }
//     if (document.getElementById("ArPopName") != null){
//       document.getElementById("ArPopName").innerHTML = `NOW AT ${locationAtInfo.FirstName_LastName_UK}`

//     }
//     console.log("LOCA " + locationAtInfo.name)

   


// ;
//   };


  function locate(e){

   
  
    var lon = e.coords.longitude;
    var lat = e.coords.latitude
     var position = [lon, lat];
    

    //  var mileDist = 0.00932057;
    //  var mileDist =0.0186411;
    //  var mileDist = 0.03;

    var mileDist = 5;

      console.log("LOCATIONS22 AT:  ----------------------------")
      console.log("RANGE " + mileDist + " miles")

      var newLocation = ""
      let locationsNear = [];
      let smallestDist = 100000;
     for (var i = 0; i < locationData.length; i++){
      // document.write("<br><br>array index: " + i);
      var obj = locationData[i];

      // console.log(obj)
      var thisCoord = [obj.longitude,obj.latitude, ]


      // for (var key in obj){
      //   console.log(key)
      // }

      var diff = [(position[0] - thisCoord[0])*54.6,(position[1] - thisCoord[1])*69]
      var dist =  Math.sqrt( diff[0] * diff[0] + diff[1] * diff[1] )

      console.log("THIS DIST",i,dist,smallestDist)

      if (dist < mileDist && dist < smallestDist){

    
        if ("id_" +  obj.id in previewData){
        smallestDist = dist;
        locationAtInfo = obj;
        // document.location.href=`ar/${locationAtInfo.id}`
        // alert("IN SMALLEST DIST", smallestDist);
        locationsNear = [obj];
        }
   
      }

      setLocationsAvailable(locationsNear)
      
      setIsActive(true);
    }

    if (newLocation !== prevlocation){
  
      prevlocation = newLocation;

    }
    console.log("--------------------------------------------")

    // console.log(position);
    // console.log("INBETWEEN");
    // console.log(coords);
    // var diff = [(position[0] - coords[0])*54.6,(position[1] - coords[1])*69]

    // console.log(diff);


    // var dist =  Math.sqrt( diff[0] * diff[0] + diff[1] * diff[1] )
    // console.log("DIFF 1",diff)
    // console.log("DIST 2", dist);


    // console.log("MILES BETWEEN", dist);


    
  }



  const n = 5;

  function setStar(Num){
    // alert(Num)


    var slides = document.getElementsByClassName("star-review");

    for (var i = 0; i < slides.length; i++) {

      slides[i].classList.remove("select-star");
    }

    for (var i = 0; i < slides.length; i++) {

        slides[i].classList.add("select-star");
        if (Num == i) break;
    }
  }

  return (
    <main className="main__body-mapData">
  
      <p  className="find__your-location">find your location ➤</p>

      <main-Map id="mapcontainer"></main-Map>

      <preview-collection>
          {locationsAvailable.map((loc,index) => {

            setTimeout(() => {
              document.getElementById("loc-preview-" + loc.id).classList.remove("loc-prev-start")
            }, 200*index);

              return (
              
                <img class="location-preview loc-prev-start"   onClick={async function(){

           
                  if (document.getElementById("loc-preview-" + loc.id).classList.value.includes('thumbnail-big')){

                    console.log("IN HERE NOW");
                    await getGyro();
                    navigatePage("/ar/" + loc.id)
                  }else{

                    for (let elem of document.getElementsByClassName("location-preview ")) {
                      elem.classList.remove("thumbnail-big");
                  }

                    document.getElementById("loc-preview-" + loc.id).classList.add('thumbnail-big')
             
                  }



                }}  value={loc.id}   id={"loc-preview-" + loc.id}
                   src={"id_" + loc.id in previewData != null ? previewData["id_" + loc.id] : "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/blank.png"}></img>
             
              );
            })}
            {/* <img class="location-preview" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/testing.png"></img>
            <img class="location-preview" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/testing.png"></img> */}

            {/* </location-preview> */}
      </preview-collection>

{/* 

      <rating-back class="hideReviewBack" id="ratingBack" >
            <rating-pop-up>
                    <rating-text>Please Rate Your Experience</rating-text>
                    <rating-button>  {[...Array(n)].map((e, i) => <img class="star-review" src={star} onClick={()=>{setStar(i)}} ></img>)}</rating-button>
                    <rating-options><rating-option onClick={hideReview}>Cancel</rating-option ><rating-option onClick={saveReview}>Select</rating-option></rating-options>
            </rating-pop-up>
      </rating-back> */}

    </main>
    
  );
};
