import * as React from "react";

import { HomePageCard } from "./HomePageCard";
import "./Home.css";
import { googleLogin } from "../../../firebase";
import Navbar from "../../Navigation/Navbar";
import { useNavigate } from "react-router-dom";
import arHiveLogo  from "../Home/ArHiveLogo.png"


let navigate;

export function goPageFromHome(string) {
  navigate(string);
}
function goMap(){
  goPageFromHome("/mapData")
}

export const Home = () => {
  navigate = useNavigate();




  return (
    <div class="homeCard"  className="cardP">


<div class="title">

  <title-card>STREET STORY</title-card>
    <sign-ins>

  {/* <button class="login-with-google-btn" onClick={googleLogin} value="Google Sign In">Sign In with Google</button><br></br> */}
  {/* <button class="login-with-facebook-btn"  value="Facebook Sign In">Sign In with Facebook</button> */}

  </sign-ins>
</div>
<img class="home-image" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/1_Layer1.png"></img>
  <img class="home-image" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/1_Layer2.png"></img>
  <img class="home-image" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/1_Layer3.png"></img>
  <img class="arHiveLogo" src={arHiveLogo}></img>
</div>



  );
};
