
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, onAuthStateChanged  } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import { goPageFromHome } from "./components/Pages/Home/Home";
import { getStorage, ref ,uploadBytes } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmxgIFgeRYDfjYOA9AcK6YKxFUfszq4A4",
  authDomain: "street-story-d0ed0.firebaseapp.com",
  databaseURL: "https://street-story-d0ed0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "street-story-d0ed0",
  storageBucket: "street-story-d0ed0.appspot.com",
  messagingSenderId: "281813766954",
  appId: "1:281813766954:web:e789dff2547fd3518a0453",
  measurementId: "G-1VZW82TWFM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
// Create a root reference
const storage = getStorage();

document.addEventListener('contextmenu', event => event.preventDefault());

export const db = getFirestore(app);

console.log("FIRESTART")



const provider = new GoogleAuthProvider();

const faceBookProvider = new FacebookAuthProvider();


console.log(provider)

let userinfo = {}

export function getUserinfo(){
    return userinfo;
}


function getDate(){
      const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    let hh = today.getHours();
    let min = today.getMinutes();
    let ss = today.getSeconds();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    if (hh < 10) hh = '0' + hh;
    if (min < 10) min = '0' + min;
    if (ss < 10) ss = '0' + ss;

    return  dd + '.' + mm + '.' + yyyy + " | " + hh + ":" + min + ":" + ss;
}

console.log("DATE " + getDate())
function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;

}

export function sendFeedback(rating, type, text,image){

  let date = getDate()
  let id = ` ${date}`
  setDoc(doc(db, "User Feedback",id ), {
    rating:rating,
    type: type,
    feedback: text,
    date: date,
  });


  const storageRef = ref(storage, id);

  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, dataURItoBlob(image)).then((snapshot) => {
    console.log('Uploaded a blob or file!');
  });
  alert("Thank you for your feedback!")
}


export function userRating(num){

  let date = getDate()
  let id = `${date}`
  setDoc(doc(db, "User Rating",id ), {
    rating:num,
  });


  const storageRef = ref(storage, id);
}

// checkuserTest();


document.getElementById("beta-link").addEventListener("click",function(){
  navigatePage("/contact")

});




onAuthStateChanged(auth, (user) => {
  return;
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    userinfo = user;
    console.log("USER NOW ")
    console.log(user)
    let page = window.location.pathname
  
    if (page == "/"){
      navigatePage("/mapData")
    }


  } else {

    let page = window.location.pathname

    console.log(window.location)
  
    if (page != "/"){
      navigatePage("/")
    }

 
  }
});

export function logout(){
  console.log("LOGOUT")

  auth.signOut().then(function() {
    // Sign-out successful.
  }, function(error) {
    // An error happened.
  });

}



function checkMobile() {
  if (navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)) {
     return true
  } else {
    return false
  }
}

let persistantPass = "";
async function checkAllowed(){


  console.log("CHECK MOBILE " + checkMobile() + " " + navigator.userAgent )


  if ( window.location.href.includes("disallowed")) return;

  if ( !checkMobile()) {
    window.location.replace("/disallowed?message=noMobile")
    return;
    }
  
  
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const userPasscode = urlParams.get('usercode')

  // const docRef = doc(db, "UserTesting", "Passcode");
  // const docSnap = await getDoc(docRef);
  // let passcode = "";

  // persistantPass = "";

  // if (docSnap.exists()) { passcode = docSnap.data()["main"]; }
  
  // if (userPasscode != null){

  //   if (userPasscode == passcode){
  //     persistantPass = passcode;
  //   }else{

  //     let inputCode = prompt("Please enter the user testing password");

  //     if (inputCode == passcode){
  //       persistantPass = passcode;
  //     }
  
  //   }

   

  // }else{

  //   let inputCode = prompt("Please enter the user testing password");

  //   if (inputCode == passcode){
  //     persistantPass = passcode;
  //   }

  // }

  // if (persistantPass == ""){
    
  // window.location.replace("/disallowed?message=noPasscode")

  // }else{
  //   // console.log("LOC HERE")
  //   // console.log(window.location)

  //   // window.history.pushState({"html":"","pageTitle":""},"", window.location.pathname);
  // }



}

checkAllowed();

export function navigatePage(string){
  // window.location.replace(string + `?usercode=${persistantPass}`)
  window.location.replace(string + ``)

}


//   navigator.getUserMedia(
//     { audio: false, video: true },()=>{
//       console.log("SUCCESS")
//     },()=>{}
//   )

// navigator.permissions.query({ name: "geolocation" }).then((result) => {
//   console.log(" PERMISSION QUERY ")
//   console.log(result)

//   if (result.state === "granted") {
//     // showLocalNewsWithGeolocation();
//   } else if (result.state === "prompt") {
//     // showButtonToEnableLocalNews();
//   }
//   // Don't do anything if the permission was denied.
// });










export function googleLogin(){


  console.log("SIGNING UPP NOW")
signInWithPopup(auth, provider)
  .then((result) => {
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    userinfo = result.user;
    
    console.log(userinfo)



    console.log("HOME NAV")
    navigatePage("/mapData");
    

    


  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(error.code +  " |  " + error.message);
    // ...
  });
}

export function facebookLogin(){
  signInWithPopup(auth, faceBookProvider)
  .then((result) => {
    // The signed-in user info.
    const user = result.user;

    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    const credential = FacebookAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;

    // IdP data available using getAdditionalUserInfo(result)
    // ...
  })
  .catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = FacebookAuthProvider.credentialFromError(error);

    // ...
  });
}

// facebookLogin();

