import React from "react";
import Accordion from "./Accordion";
import { AccordionData } from "./AccordionData";
import {logout} from "../../../firebase";
import logos from "../About/logos.png"

import "./About.css";

// import {Email } from "smtp-server"



export const About = () => {

  // function sendEmail() { 
  //   Email.send({ 
  //     Host: "smtp.gmail.com", 
  //     Username: "arhiveorgpublic@gmail.com", 
  //     Password: "Shop1577", 
  //     To: "arhiveorgpublic@gmail.com", 
  //     From: "arhiveorgpublic@gmail.com", 
  //     Subject: "Sending Email using javascript", 
  //     Body: "Well that was easy!!", 
  //   }) 
  //     .then(function (message) { 
  //       alert("mail sent successfully") 
  //     }); 
  // } 

  // sendEmail()
  

  return (
    <main className="main__body-about">
            {/* <logout-button onClick={logout}>Logout</logout-button> */}
      {/* <header className="main__about-header"> */}
        {/* <h2>About</h2> */}
        {/* <logout-button onClick={logout}>Logout</logout-button>
      </header> */}
      <div className="accordion">
        {AccordionData.map(({ title, content }) => (
          <Accordion title={title} content={content} />
        ))}
      </div>


          <img class="about-logos" src={logos}></img>
    </main>
  );
};
