import six from "../../img/locImgs/6.png";
import eleven from "../../img/locImgs/11.png";
import twelve from "../../img/locImgs/12.png";
import sixteen from "../../img/locImgs/16.png";
import twentyThree from "../../img/locImgs/23.png";
import thirtyFour from "../../img/locImgs/34.png";
import thirtyNine from "../../img/locImgs/39.png";
import fortyOne from "../../img/locImgs/41.png";
import fortySeven from "../../img/locImgs/47.png";

import track1 from "../Map/imgTracking/WilliamAugustusParker.fset";
import track2 from "../Map/imgTracking/WilliamAugustusParker.fset3";
import track3 from "../Map/imgTracking/WilliamAugustusParker.iset";


let antigua1_1 = require("../Ar/Poster Final/antigua(1)_L1.png")
let antigua1_2 = require("../Ar/Poster Final/antigua(1)_L2.png")

let antigua2_1 = require("../Ar/Poster Final/antigua(2)_L1.png")
let antigua2_2 = require("../Ar/Poster Final/antigua(2)_L2.png")

let antigua_1 = require("../Ar/Poster Final/antigua_L1.png")
let antigua_2 = require("../Ar/Poster Final/antigua_L2.png")

let barbados1_1 = require("../Ar/Poster Final/barbados(1)_L1.png")
let barbados1_2 = require("../Ar/Poster Final/barbados(1)_L2.png")

let barbados_1 = require("../Ar/Poster Final/barbados_L1.png")
let barbados_2 = require("../Ar/Poster Final/barbados_L2.png")

let british_guiana1_1 = require("../Ar/Poster Final/british guiana(1)_L1.png")
let british_guiana1_2= require("../Ar/Poster Final/british guiana(1)_L2.png")


let british_guiana2_1 = require("../Ar/Poster Final/british guiana(2)_L1.png")
let british_guiana2_2= require("../Ar/Poster Final/british guiana(2)_L2.png")

let british_guiana3_1 = require("../Ar/Poster Final/british guiana(3)_L1.png")
let british_guiana3_2= require("../Ar/Poster Final/british guiana(3)_L2.png")

let british_guiana_1 = require("../Ar/Poster Final/british guiana_L1.png")
let british_guiana_2= require("../Ar/Poster Final/british guiana_L2.png")

let dominica1_1 = require("../Ar/Poster Final/dominica(1)_L1.png")
let dominica1_2= require("../Ar/Poster Final/dominica(1)_L2.png")

let dominica_1 = require("../Ar/Poster Final/dominica_L1.png")
let dominica_2= require("../Ar/Poster Final/dominica_L2.png")

let grenada1_1 = require("../Ar/Poster Final/grenada(1)_L1.png")
let grenada1_2= require("../Ar/Poster Final/grenada(1)_L2.png")

let grenada2_1 = require("../Ar/Poster Final/grenada(2)_L1.png")
let grenada2_2= require("../Ar/Poster Final/grenada(2)_L2.png")

let grenada_1 = require("../Ar/Poster Final/grenada_L1.png")
let grenada_2= require("../Ar/Poster Final/grenada_L2.png")


let jamaica1_1 = require("../Ar/Poster Final/jamaica(1)_L1.png")
let jamaica1_2= require("../Ar/Poster Final/jamaica(1)_L2.png")


let jamaica2_1 = require("../Ar/Poster Final/jamaica(2)_L1.png")
let jamaica2_2= require("../Ar/Poster Final/jamaica(2)_L2.png")

let jamaica3_1 = require("../Ar/Poster Final/jamaica(3)_L1.png")
let jamaica3_2= require("../Ar/Poster Final/jamaica(3)_L2.png")

let jamaica4_1 = require("../Ar/Poster Final/jamaica(4)_L1.png")
let jamaica4_2= require("../Ar/Poster Final/jamaica(4)_L2.png")

let jamaica5_1 = require("../Ar/Poster Final/jamaica(5)_L1.png")
let jamaica5_2= require("../Ar/Poster Final/jamaica(5)_L2.png")

let jamaica6_1 = require("../Ar/Poster Final/jamaica(6)_L1.png")
let jamaica6_2= require("../Ar/Poster Final/jamaica(6)_L2.png")


let jamaica_1 = require("../Ar/Poster Final/jamaica_L1.png")
let jamaica_2= require("../Ar/Poster Final/jamaica_L2.png")

let mauritius1_1 = require("../Ar/Poster Final/mauritius(1)_L1.png")
let mauritius1_2 = require("../Ar/Poster Final/mauritius(1)_L2.png")


let mauritius2_1 = require("../Ar/Poster Final/mauritius(2)_L1.png")
let mauritius2_2 = require("../Ar/Poster Final/mauritius(2)_L2.png")

let mauritius_1 = require("../Ar/Poster Final/mauritius_L1.png")
let mauritius_2 = require("../Ar/Poster Final/mauritius_L2.png")


let montserrat1_1 = require("../Ar/Poster Final/montserrat(1)_L1.png")
let montserrat1_2 = require("../Ar/Poster Final/montserrat(1)_L2.png")

let montserrat_1 = require("../Ar/Poster Final/montserrat_L1.png")
let montserrat_2 = require("../Ar/Poster Final/montserrat_L2.png")


let st_kitts1_1 = require("../Ar/Poster Final/st kitts(1)_L1.png")
let st_kitts1_2 = require("../Ar/Poster Final/st kitts(1)_L2.png")

let st_kitts_1 = require("../Ar/Poster Final/st kitts_L1.png")
let st_kitts_2 = require("../Ar/Poster Final/st kitts_L2.png")


let tobago_1 = require("../Ar/Poster Final/tobago_L1.png")
let tobago_2 = require("../Ar/Poster Final/tobago_L2.png")

let trinidad1_1 = require("../Ar/Poster Final/trinidad(1)_L1.png")
let trinidad1_2 = require("../Ar/Poster Final/trinidad(1)_L2.png")

let trinidad2_1 = require("../Ar/Poster Final/trinidad(2)_L1.png")
let trinidad2_2 = require("../Ar/Poster Final/trinidad(2)_L2.png")

let trinidad3_1 = require("../Ar/Poster Final/trinidad(3)_L1.png")
let trinidad3_2 = require("../Ar/Poster Final/trinidad(3)_L2.png")


let trinidad_1 = require("../Ar/Poster Final/trinidad_L1.png")
let trinidad_2 = require("../Ar/Poster Final/trinidad_L2.png")

let virgin_islands_1 = require("../Ar/Poster Final/virgin islands_L1.png")
let  virgin_islands_2 = require("../Ar/Poster Final/virgin islands_L2.png")


let  back = require("../Ar/Poster Final/back.png")
// import antiga as imgs from "../../../Poster Final";



// Audto Files;

export let audioData = {};
audioData["id_9"] = require("../Ar/Audio Final/ID_9.mp3")
audioData["id_10"] = require("../Ar/Audio Final/ID_10.mp3")
audioData["id_11"] = require("../Ar/Audio Final/ID_11.mp3")
audioData["id_12"] = require("../Ar/Audio Final/ID_12.mp3")
audioData["id_13"] = require("../Ar/Audio Final/ID_13.mp3")
audioData["id_14"] = require("../Ar/Audio Final/ID_14.mp3")
audioData["id_15"] = require("../Ar/Audio Final/ID_15.mp3")
audioData["id_16"] = require("../Ar/Audio Final/ID_16.mp3")
audioData["id_17"] = require("../Ar/Audio Final/ID_17.mp3")
audioData["id_19"] = require("../Ar/Audio Final/ID_19.mp3")
audioData["id_20"] = require("../Ar/Audio Final/ID_20.mp3")
audioData["id_21"] = require("../Ar/Audio Final/ID_21.mp3")
audioData["id_22"] = require("../Ar/Audio Final/ID_22.mp3")
audioData["id_23"] = require("../Ar/Audio Final/ID_23.mp3")
audioData["id_24"] = require("../Ar/Audio Final/ID_24.mp3")
audioData["id_25"] = require("../Ar/Audio Final/ID_25.mp3")
audioData["id_27"] = require("../Ar/Audio Final/ID_27.mp3")
audioData["id_28"] = require("../Ar/Audio Final/ID_28.mp3")
audioData["id_29"] = require("../Ar/Audio Final/ID_29.mp3")
audioData["id_31"] = require("../Ar/Audio Final/ID_31.mp3")
audioData["id_33"] = require("../Ar/Audio Final/ID_33.mp3")
audioData["id_34"] = require("../Ar/Audio Final/ID_34.mp3")
audioData["id_35"] = require("../Ar/Audio Final/ID_35.mp3")
audioData["id_36"] = require("../Ar/Audio Final/ID_36.mp3")
audioData["id_37"] = require("../Ar/Audio Final/ID_37.mp3")
audioData["id_39"] = require("../Ar/Audio Final/ID_39.mp3")
audioData["id_40"] = require("../Ar/Audio Final/ID_40.mp3")
audioData["id_41"] = require("../Ar/Audio Final/ID_41.mp3")
audioData["id_43"] = require("../Ar/Audio Final/ID_43.mp3")
audioData["id_44"] = require("../Ar/Audio Final/ID_44.mp3")
audioData["id_45"] = require("../Ar/Audio Final/ID_45.mp3")
audioData["id_46"] = require("../Ar/Audio Final/ID_46.mp3")
audioData["id_47"] = require("../Ar/Audio Final/ID_47.mp3")
audioData["id_48"] = require("../Ar/Audio Final/ID_48.mp3")
audioData["id_50"] = require("../Ar/Audio Final/ID_50.mp3")
audioData["id_52"] = require("../Ar/Audio Final/ID_52.mp3")
audioData["id_53"] = require("../Ar/Audio Final/ID_53.mp3")
audioData["id_56"] = require("../Ar/Audio Final/ID_56.mp3")
audioData["id_58"] = require("../Ar/Audio Final/ID_58.mp3")
audioData["id_68"] = require("../Ar/Audio Final/ID_68.mp3")
audioData["id_70"] = require("../Ar/Audio Final/ID_70.mp3")
audioData["id_81"] = require("../Ar/Audio Final/ID_81.mp3")
audioData["id_82"] = require("../Ar/Audio Final/ID_82.mp3")
audioData["id_83"] = require("../Ar/Audio Final/ID_83.mp3")
audioData["id_84"] = require("../Ar/Audio Final/ID_84.mp3")
audioData["id_85"] = require("../Ar/Audio Final/ID_85.mp3")
audioData["id_86"] = require("../Ar/Audio Final/ID_86.mp3")
audioData["id_87"] = require("../Ar/Audio Final/ID_87.mp3")
audioData["id_88"] = require("../Ar/Audio Final/ID_88.mp3")

// ThumbnaiFl Images;

export let previewData = {};
previewData["id_8"] = require("../Map/locationFinal/8.jpg")
previewData["id_9"] = require("../Map/locationFinal/9.jpg")
previewData["id_10"] = require("../Map/locationFinal/10.jpg") 
previewData["id_11"] = require("../Map/locationFinal/11.jpg")
previewData["id_12"] = require("../Map/locationFinal/12.jpg")
previewData["id_13"] = require("../Map/locationFinal/13.jpg")
previewData["id_14"] = require("../Map/locationFinal/14.jpg")
previewData["id_15"] = require("../Map/locationFinal/15.jpg")
previewData["id_16"] = require("../Map/locationFinal/16.jpg")
previewData["id_17"] = require("../Map/locationFinal/17.jpg")
previewData["id_19"] = require("../Map/locationFinal/19.jpg")
previewData["id_20"] = require("../Map/locationFinal/20.jpg")
previewData["id_21"] = require("../Map/locationFinal/21.jpg")
previewData["id_22"] = require("../Map/locationFinal/22.jpg")
previewData["id_23"] = require("../Map/locationFinal/23.jpg")
previewData["id_24"] = require("../Map/locationFinal/24.jpg")
previewData["id_25"] = require("../Map/locationFinal/25.jpg")
previewData["id_26"] = require("../Map/locationFinal/26.jpg")
previewData["id_27"] = require("../Map/locationFinal/27.jpg")
previewData["id_28"] = require("../Map/locationFinal/28.jpg")
previewData["id_29"] = require("../Map/locationFinal/29.jpg")
previewData["id_30"] = require("../Map/locationFinal/30.jpg")
previewData["id_31"] = require("../Map/locationFinal/31.jpg")
previewData["id_32"] = require("../Map/locationFinal/32.jpg")
previewData["id_33"] = require("../Map/locationFinal/33.jpg")
previewData["id_34"] = require("../Map/locationFinal/34.jpg")
previewData["id_35"] = require("../Map/locationFinal/35.jpg")
previewData["id_36"] = require("../Map/locationFinal/36.jpg")
previewData["id_37"] = require("../Map/locationFinal/37.jpg")
previewData["id_38"] = require("../Map/locationFinal/38.jpg")
previewData["id_39"] = require("../Map/locationFinal/39.jpg")
previewData["id_40"] = require("../Map/locationFinal/40.jpg")
previewData["id_41"] = require("../Map/locationFinal/41.jpg")
previewData["id_43"] = require("../Map/locationFinal/43.jpg")
previewData["id_44"] = require("../Map/locationFinal/44.jpg")
previewData["id_45"] = require("../Map/locationFinal/45.jpg")
previewData["id_46"] = require("../Map/locationFinal/46.jpg")
previewData["id_47"] = require("../Map/locationFinal/47.jpg")
previewData["id_50"] = require("../Map/locationFinal/50.jpg")
previewData["id_51"] = require("../Map/locationFinal/51.jpg")
previewData["id_52"] = require("../Map/locationFinal/52.jpg")
previewData["id_53"] = require("../Map/locationFinal/53.jpg")
previewData["id_54"] = require("../Map/locationFinal/54.jpg")
previewData["id_56"] = require("../Map/locationFinal/56.jpg")
previewData["id_58"] = require("../Map/locationFinal/58.jpg")
previewData["id_68"] = require("../Map/locationFinal/68.jpg")
previewData["id_70"] = require("../Map/locationFinal/70.jpg")

previewData["id_81"] = require("../Map/locationFinal/81.jpg")
previewData["id_82"] = require("../Map/locationFinal/82.jpg")
previewData["id_83"] = require("../Map/locationFinal/83.jpg")
previewData["id_84"] = require("../Map/locationFinal/84.jpeg")
previewData["id_85"] = require("../Map/locationFinal/85.jpg")
previewData["id_86"] = require("../Map/locationFinal/86.jpg")
previewData["id_87"] = require("../Map/locationFinal/87.jpg")
previewData["id_88"] = require("../Map/locationFinal/88.jpeg")


export const arData = [
  {
    name: "back",
    arSrc: back,
  },
  {
    name: "jamaica",
    arSrc: [
    [jamaica_1,jamaica_2],
    [jamaica1_1,jamaica1_2],
    [jamaica2_1,jamaica2_2],
    [jamaica3_1,jamaica3_2],
    [jamaica4_1,jamaica4_2],
    [jamaica5_1,jamaica5_2],
    [jamaica6_1,jamaica6_2],

    ]
  },
  {
    name: "tobago",
    arSrc: [
      [tobago_1,tobago_2],
  
      ]
  },
  { name: "virgin islands" ,
  arSrc: [
    [virgin_islands_1,virgin_islands_2],

    ]

},
  {
    name: "british guiana",
    arSrc: [
      [british_guiana_1,british_guiana_2],
      [british_guiana_1,british_guiana_2],
      [british_guiana1_1,british_guiana1_2],
      [british_guiana2_1,british_guiana2_2],
      [british_guiana3_1,british_guiana3_2],

  
      ]
  },
  {
    name: "st vincent",
    arSrc: [
      [st_kitts_1,st_kitts_2],
      [st_kitts1_1,st_kitts1_2],
      ]
  },
  {
    name: "montserrat",
    arSrc: [
      [montserrat_1,montserrat_2],
      [montserrat1_1,montserrat1_2],
    ]
  },
  {
    name: "antigua",
    arSrc: [
      [antigua_1,antigua_2],
      [antigua1_1,antigua1_2],
      [antigua2_1,antigua2_2],
    ]

  },
  {
     name: "st kitts", 
    
     arSrc: [
      [st_kitts_1,st_kitts_2],
      [st_kitts1_1,st_kitts1_2],
    ]
  },
  { name: "grenada",
    
  arSrc: [
    [grenada_1,grenada_2],
    [grenada1_1,grenada1_2],
    [grenada2_1,grenada2_1],
  ] 
},
  { name: "barbados" ,
    
  arSrc: [
    [barbados_1,barbados_2],
    [barbados1_1,barbados1_2],
  ] 
},
  { name: "mauritius"
  ,
    
  arSrc: [
    [mauritius_1,mauritius_2],
    [mauritius1_1,mauritius1_2],
    [mauritius2_1,mauritius2_2],
  ] 
 },
  { name: "trinidad" 
  ,
    
  arSrc: [
    [trinidad_1,trinidad_2],
    [trinidad1_1,trinidad1_2],
    [trinidad2_1,trinidad2_2],
    [trinidad3_1,trinidad3_2],
  ] 

},
  { name: "dominica" 
  ,
    
  arSrc: [
    [dominica_1,dominica_2],
    [dominica1_1,dominica1_2],
  ] 
},
]
track1 = track1;
track2 = track2;
track3 = track3;
export const locationData2 = [
  {
    id: 48,
    FirstName_LastName_UK: "Test",
    city_UK: "Brighton",
    Island: "British Guiana 1475",
    people_enslaved: 88,
    compensation: "£4,500",
    Money_today: "£73,1520.00",
    latitude: 50.8223457,
    longitude: -0.1529618,
    image: fortySeven,
    alt: "id :47",
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/WilliamAugustusParker.jpg",
    //  trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/WilliamAugustusParker",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/1_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/1_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  }, {
    id: 47,
    FirstName_LastName_UK: "William Augustus Parker",
    city_UK: "Brighton",
    Island: "British Guiana 1475",
    people_enslaved: 88,
    compensation: "£4,500",
    Money_today: "£73,1520.00",
    latitude: 50.8232106,
    longitude: -0.1531099,
    image: fortySeven,
    alt: "id :47",
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/WilliamAugustusParker.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/WilliamAugustusParker",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/1_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/1_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },

  {

    id: 100,
    FirstName_LastName_UK: " Library test point 1",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.824866,
    longitude: -0.138409,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/1.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/1",
    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/1_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/1_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 101,
    FirstName_LastName_UK: " test point 2",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.824754,
    longitude: -0.138269,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/2.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/2",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/2_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/2_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 102,
    FirstName_LastName_UK: "test point 3",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.824212,
    longitude: -0.13814,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/3.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/3",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/3_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/3_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 103,
    FirstName_LastName_UK: "test point 4",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.823781,
    longitude: -0.137797,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/4.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/4",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/4_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/4_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 104,
    FirstName_LastName_UK: "test point 5",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.824202,
    longitude: -0.136798,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/5.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/5",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/5_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/5_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 105,
    FirstName_LastName_UK: "test point 6",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.82376736,
    longitude: -0.13660351,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/6.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/6",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/6_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/6_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 106,
    FirstName_LastName_UK: "test point 7",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.82355963,
    longitude: -0.13749511,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/7.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/7",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/7_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/7_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 107,
    FirstName_LastName_UK: "test point 8",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8246041,
    longitude: -0.14103278,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/8.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/8",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/8_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/8_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 108,
    FirstName_LastName_UK: "test point 9",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8259129,
    longitude: -0.140958,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/9.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/9",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/9_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/9_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  {
    id: 109,
    FirstName_LastName_UK: "test point 10",
    city_UK: "none",
    Island: "none",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8259603,
    longitude: -0.1384436,
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/10.jpg",
    trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/UserTest/10",

    color: "green",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/10_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/10_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
]




export const locationData = [
  {
    id: -48,
    FirstName_LastName_UK: "Test",
    city_UK: "Brighton",
    Island: "antigua",
    people_enslaved: 88,
    compensation: "£4,500",
    Money_today: "£73,1520.00",
    latitude: 50.8223457,
    longitude: -0.1529618,
    image: fortySeven,
    alt: "id :47",
    preview: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/WilliamAugustusParker.jpg",
    //  trackingSrc: "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/WilliamAugustusParker",
    arData:
      <ar-content>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/1_Layer1.png"></img>
        <img class="ar-layer" src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/Poster/userTestPoster/1_Layer2.png"></img>
        <audio controls autoplay class="ar-layer">
          <source src="https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/testAudio.mp3" type="audio/mpeg" />
        </audio>
      </ar-content>
  },
  // {

  //   id: 1001,
  //   FirstName_LastName_UK: "Test William King Mitchell",
  //   city_UK: "Western Lodge, (sillwood place )",
  //   Island: "Jamaica",
  //   people_enslaved: 199,
  //   compensation: "£2,482",
  //   Money_today: "£403,473.90",
  //   latitude: 50.82019364516403, 
  //   longitude: -0.13928416400148644,

  // },
  {

    id: 10010,
    FirstName_LastName_UK: "Test Data",
    city_UK: "Western Lodge, (sillwood place )",
    Island: "Jamaica",
    people_enslaved: 199,
    compensation: "£2,482",
    Money_today: "£403,473.90",
    latitude:50.82885933446798 , 
    longitude: -0.1337738440292332,

  },
  // {

  //   id: 10011,
  //   FirstName_LastName_UK: "Test William King Mitchell",
  //   city_UK: "Western Lodge, (sillwood place )",
  //   Island: "Jamaica",
  //   people_enslaved: 199,
  //   compensation: "£2,482",
  //   Money_today: "£403,473.90",
  //   latitude: 50.823696182142406,
  //   longitude: -0.13867572034983752,

  // },
  {

    id: 48,
    FirstName_LastName_UK: "William King Mitchell",
    city_UK: "Western Lodge, (sillwood place )",
    Island: "Antigua 38 (Marble Hill) ",
    people_enslaved: 199,
    compensation: "£2,482",
    Money_today: "£403,473.90",
    latitude: 50.823664,
    longitude: -0.153362,

  },


  {
    id: 1,
    FirstName_LastName_UK: "Michael Nihell Bovell",
    city_UK: "Worthing",
    Island: "British Guiana:",
    people_enslaved: 394,
    compensation: "£1,3274",
    Money_today: "£2,157821.40",
    latitude: 50.813492,
    longitude: -0.372458,
    color: "red",
  },
  {
    id: 2,
    FirstName_LastName_UK: "William Bryan",
    city_UK: "Worthing",
    Island: "No Island",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8179,
    longitude: -0.3729,
    color: "red",
  },
  {
    id: 3,
    FirstName_LastName_UK: "William / Catherine Carter (nee Hayne)",
    city_UK: "Worthing",
    Island: "Jamaica",
    people_enslaved: 82,
    compensation: "£1,712.00",
    Money_today: "£278302.70",
    latitude: 50.8179,
    longitude: -0.3729,
    color: "red",
  },
  {
    id: 4,
    FirstName_LastName_UK: "William Carter",
    city_UK: "Worthing",
    Island: "Jamaica",
    people_enslaved: 5,
    compensation: "£107",
    Money_today: "£17,393.90",
    latitude: 50.8179,
    longitude: -0.3729,
    color: "red",
  },
  {
    id: 5,
    FirstName_LastName_UK: "Charles Beckford Long",
    city_UK: "Worthing",
    Island: "Jamaica",
    people_enslaved: 234,
    compensation: "£ 3991",
    Money_today: "£ 646,826.20",
    latitude: 50.8179,
    longitude: -0.3729,
    color: "red",
  },
  {
    id: 6,
    FirstName_LastName_UK: "David Lyon Junior",
    city_UK: "Worthing",
    Island: "Jamaica",
    people_enslaved: 2579,
    compensation: "£46,854.00",
    Money_today: "£7,616,586.20",
    latitude: 50.811389,
    longitude: -0.431813,

  },
  {
    id: 7,
    FirstName_LastName_UK: "william Nedham",
    city_UK: "Worthing",
    Island: "Jamaica",
    people_enslaved: 194,
    compensation: "£3,669.00",
    Money_today: "£596,432.60",
    latitude: 50.810706,
    longitude: -0.364391,
    color: "red",
  },
  {
    id: 8,
    FirstName_LastName_UK: "Kenrick Francis Saunders",
    city_UK: "Brighton",
    Island: "Barbados 4601",
    people_enslaved: 164,
    compensation: "£3,577.00",
    Money_today: "£581,477.10",
    latitude: 50.827716,
    longitude: -0.141673,
    color: "red",
  },
  {
    id: 9,
    FirstName_LastName_UK: "Ann Napier (née Stirling)",
    city_UK: "Brighton",
    Island: "Tobago 5",
    people_enslaved: 123,
    compensation: "£2,576.00",
    Money_today: "£418,754.60",
    latitude: 50.826033,
    longitude: -0.142296,
  },
  {
    id: 10,
    FirstName_LastName_UK: "Anna Elizabeth Anderson",
    city_UK: "Brighton",
    Island: "Vigin Iislands 219",
    people_enslaved: 160,
    compensation: "£1,111.00",
    Money_today: "£180,604.20",
    latitude: 50.8289,
    longitude: -0.141,

  },
  {
    id: 11,
    FirstName_LastName_UK: "Caroline Atkinson Swaby",
    city_UK: "Brighton",
    Island: "Jamaica Manchester 116",
    people_enslaved: 453,
    compensation: "£ 8,633",
    Money_today: "£1,403,380.50",
    latitude: 50.8216405,
    longitude: -0.1308382,

  },
  {
    id: 12,
    FirstName_LastName_UK: "Caroline Ellen Anderson",
    city_UK: "Brighton",
    Island: "Virgin Islands 219",
    people_enslaved: 160,
    compensation: "£1,111",
    Money_today: "£180,604.20",
    latitude: 50.8191049,
    longitude: -0.1266938,

  },
  {
    id: 13,
    FirstName_LastName_UK: "Catherine Wratislaw (née Barry)",
    city_UK: "Brighton",
    Island: "British Guiana 2492",
    people_enslaved: 108,
    compensation: "£ 5,813",
    Money_today: "£944,961.30",
    latitude: 50.827995,
    longitude: -0.141479,

  },
  {
    id: 14,
    FirstName_LastName_UK: "Cecilia Blake ",
    city_UK: "Brighton",
    Island: "St Vincent 470",
    people_enslaved: 188,
    compensation: "£5,052",
    Money_today: "£821,253.10",
    latitude: 50.822158,
    longitude: -0.144199,
    color: "red",
  },
  {
    id: 15,
    FirstName_LastName_UK: "Charles Lushington",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: 332,
    compensation: "£5,849",
    Money_today: "£950,813.40",
    latitude: 50.8198085,
    longitude: -0.1355206,

  },
  {
    id: 16,
    FirstName_LastName_UK: "Cornelius Paine",
    city_UK: "Brighton",
    Island: "Montserrat 110A",
    people_enslaved: 323,
    compensation: "£5,061",
    Money_today: "£822,716.20",
    latitude: 50.8163271,
    longitude: -0.1121712,
  },
  {
    id: 17,
    FirstName_LastName_UK: "Edward Hoare",
    city_UK: "Brighton",
    Island: "Jamaica Manchester 338 (Mile Gully)",
    people_enslaved: 998,
    compensation: "£19,400",
    Money_today: "£3,153,664.00",
    latitude: 50.8242199,
    longitude: -0.14481,
  },
  {
    id: 18,
    FirstName_LastName_UK: "Edward Monk",
    city_UK: "Cliffe",
    Island: "Antigua 15",
    people_enslaved: 28,
    compensation: "£400",
    Money_today: "£65,024.00",
    latitude: 50.8736115,
    longitude: 0.0192103,
  },
  {
    id: 19,
    FirstName_LastName_UK: "George Monk",
    city_UK: "Brighton",
    Island: "Antigua 15",
    people_enslaved: 27,
    compensation: "£400",
    Money_today: "£65,024.00",
    latitude: 50.822681,
    longitude: -0.14408,
  },
  {
    id: 20,
    FirstName_LastName_UK: "George Washington Philips & Edward Lumley Wilson ",
    city_UK: "Brighton",
    Island: "St Kitts 440",
    people_enslaved: 161,
    compensation: "£2,678.00",
    Money_today: "£435,335.70",
    latitude: 50.819691,
    longitude: -0.13322128,
  },
  {
    id: 21,
    FirstName_LastName_UK: "Georgina Frances de Peyronnet (née Whitfield)",
    city_UK: "Brighton",
    Island: "St Vincent",
    people_enslaved: 337,
    compensation: "£11,279.0s0",
    Money_today: "£1,833,514.20",
    latitude: 50.819733,
    longitude: -0.133217,
  },
  {
    id: 22,
    FirstName_LastName_UK: "Henry Yarburgh Everitt",
    city_UK: "Brighton",
    Island: "Antigua 487A [&B] (Sion Hill)",
    people_enslaved: 176,
    compensation: "£2,734",
    Money_today: "£444,439.00",
    latitude: 50.824996,
    longitude: -0.14285,
  },
  {
    id: 23,
    FirstName_LastName_UK: "Archibald MacDonald",
    city_UK: "Brighton",
    Island: "St Vincent 497A & B (Calder",
    people_enslaved: 610,
    compensation: "£15,765",
    Money_today: "£2,562,758.40",
    latitude: 50.8172686,
    longitude: -0.1174275,

  },
  {
    id: 24,
    FirstName_LastName_UK: "John Brome",
    city_UK: "Brighton",
    Island: "Barbados 4800 (Bromefield)",
    people_enslaved: 285,
    compensation: "£6,542",
    Money_today: "£1,063,467.50",
    latitude: 50.8249099,
    longitude: -0.159461,
  },
  {
    id: 25,
    FirstName_LastName_UK: "John Dawkins",
    city_UK: "Brighton",
    Island: "Jamaica Trelawney 411 (York)",
    people_enslaved: 246,
    compensation: "£4,668",
    Money_today: "£758,830.10",
    latitude: 50.8145597,
    longitude: -0.1081976,
  },
  {
    id: 26,
    FirstName_LastName_UK: "John Eldad Walters",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: 916,
    compensation: "£16,142",
    Money_today: "£2,6240,43.50",
    latitude: 50.8197701,
    longitude: -0.1254411,
  },
  {
    id: 27,
    FirstName_LastName_UK: "John Henry Noding ",
    city_UK: "Brighton",
    Island: "Tobago",
    people_enslaved: 118,
    compensation: "£2,800",
    Money_today: "£455,168.00",
    latitude: 50.825742,
    longitude: -0.142434,
  },
  {
    id: 28,
    FirstName_LastName_UK: "John Laing ",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: 57,
    compensation: "£1,164",
    Money_today: "£189,219.80",
    latitude: 50.8265146,
    longitude: -0.1520861,
  },
  {
    id: 29,
    FirstName_LastName_UK: "John Roach Bovell and John Matthews Boswell junior",
    city_UK: "Brighton",
    Island: "British Guiana",
    people_enslaved: 392,
    compensation: "£20,820.00",
    Money_today: "£3,384,499.20",
    latitude: 50.828287,
    longitude: -0.141603,
  },
  {
    id: 30,
    FirstName_LastName_UK: "Louisa Barry",
    city_UK: "Brighton",
    Island: "British Guiana",
    people_enslaved: 108,
    compensation: "£5,813.00",
    Money_today: "£944,961.30",
    latitude: 50.828653,
    longitude: -0.141294,
  },
  {
    id: 31,
    FirstName_LastName_UK: "Mary Chandler",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: 322,
    compensation: "£5,849.00",
    Money_today: "£950,813.40",
    latitude: 50.822189,
    longitude: -0.134237,
  },
  {
    id: 32,
    FirstName_LastName_UK: "Mary Johnson (née Francis)",
    city_UK: "Brighton",
    Island: "Antigua",
    people_enslaved: 264,
    compensation: "£3,461.00",
    Money_today: "£562,620.20",
    latitude: 50.824364,
    longitude: -0.143171,
  },
  {
    id: 33,
    FirstName_LastName_UK: "Philip Laycock Story",
    city_UK: "Brighton",
    Island: "Montserrat",
    people_enslaved: 128,
    compensation: "£1,977.00",
    Money_today: "£321,381.10",
    latitude: 50.8177347,
    longitude: -0.1101429,
  },
  {
    id: 34,
    FirstName_LastName_UK: "Ralph Rice",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: 191,
    compensation: "£3,579.00",
    Money_today: "£581,802.20",
    latitude: 50.8183453,
    longitude: -0.1253383,
  },
  {
    id: 35,
    FirstName_LastName_UK: "Richard Alexander Oswald",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: 297,
    compensation: "£5,445",
    Money_today: "£885,139.20",
    latitude: 50.826829,
    longitude: -0.141971,
  },
  {
    id: 36,
    FirstName_LastName_UK: "Richard Barnes Bell ",
    city_UK: "Brighton",
    Island: "Mauritius",
    people_enslaved: 19,
    compensation: "£632",
    Money_today: "£102,737.90",
    latitude: 50.8198916,
    longitude: -0.1370037,
  },
  {
    id: 37,
    FirstName_LastName_UK: "Richard Silvester Cahill",
    city_UK: "Brighton",
    Island: "Trinidad",
    people_enslaved: 131,
    compensation: "£3,769",
    Money_today: "£612,688.60",
    latitude: 50.825402,
    longitude: -0.142655,
  },
  {
    id: 38,
    FirstName_LastName_UK: "Robert Home Gordon",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.827318,
    longitude: -0.141854,
  },
  {
    id: 39,
    FirstName_LastName_UK: "Samuel Anderson and Anna Rebecca Anderson (née Ruthven)",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: 252,
    compensation: "£4,866",
    Money_today: "£791,017.00",
    latitude: 50.8247482,
    longitude: -0.1531025,
  },
  {
    id: 40,
    FirstName_LastName_UK: "Thomas Martin ",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: 50,
    compensation: "£1,075",
    Money_today: "£174,752.00",
    latitude: 50.8258599,
    longitude: -0.151302236,
  },
  {
    id: 41,
    FirstName_LastName_UK: "Thomson Hankey ",
    city_UK: "Brighton",
    Island: "Grenada 445 (Tempe Estate)",
    people_enslaved: 1919,
    compensation: "£49,706",
    Money_today: "£8,080,207.40",
    latitude: 50.8239702,
    longitude: -0.1584867,
  },
  {
    id: 42,
    FirstName_LastName_UK: "Letitia Taylor (née Nembhard)",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.81648,
    longitude: -0.115245,
  },
  {
    id: 43,
    FirstName_LastName_UK: "Edward Lumley Wilson",
    city_UK: "Brighton",
    Island: "St Kitts 440 (Stone Fort)",
    people_enslaved: 161,
    compensation: "£2,678",
    Money_today: "£435,335.70",
    latitude: 50.8198343,
    longitude: -0.1333396,
  },
  {
    id: 44,
    FirstName_LastName_UK: "Robert French Kirwan",
    city_UK: "Brighton",
    Island: "Montserrat 35",
    people_enslaved: 2,
    compensation: "£50",
    Money_today: "£8,128.00",
    latitude: 50.8205,
    longitude: -0.1363,
  },
  {
    id: 45,
    FirstName_LastName_UK: "William Williams Blake",
    city_UK: "Brighton",
    Island: "Jamaica Westmoreland 29 (Shrewsbury)",
    people_enslaved: 329,
    compensation: "£545 4",
    Money_today: "£88,595.20",
    latitude: 50.8218675,
    longitude: -0.1511411,
  },
  {
    id: 46,
    FirstName_LastName_UK: "Alexander Maximilien Touzalin",
    city_UK: "Brighton",
    Island: "Jamaica Westmoreland 591",
    people_enslaved: 5,
    compensation: "£93",
    Money_today: "£15,118.10",
    latitude: 50.822841,
    longitude: -0.148312,
  },
  {
    id: 47,
    FirstName_LastName_UK: "William Augustus Parker",
    city_UK: "Brighton",
    Island: "British Guiana 1475",
    people_enslaved: 88,
    compensation: "£4,500",
    Money_today: "£73,1520.00",
    latitude: 50.8232106,
    longitude: -0.1531099,



  },

  {
    id: 49,
    FirstName_LastName_UK: "Katherine Ann Hinds Cobham (née Skinner)",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.824,
    longitude: -0.1536,
  },
  {
    id: 50,
    FirstName_LastName_UK: "John Cockerell",
    city_UK: "Brighton",
    Island: "Mauritius 2788   (X6 plantations)",
    people_enslaved: 79,
    compensation: "£2,444",
    Money_today: "£397,296.60",
    latitude: 50.8244792,
    longitude: -0.1621662,
  },
  {
    id: 51,
    FirstName_LastName_UK: "Agnes Joanna Darlot (née Troup)",
    city_UK: "Brighton",
    Island: "Codrington Place - Further investigation",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8247807,
    longitude: -0.1519241,
  },
  {
    id: 52,
    FirstName_LastName_UK: "Joshua Kentish",
    city_UK: "Brighton",
    Island: "Antigua 617 (Town of St John)",
    people_enslaved: 32,
    compensation: "£550",
    Money_today: "£89,408.00",
    latitude: 50.826,
    longitude: -0.155,
  },
  {
    id: 53,
    FirstName_LastName_UK: "Skeffington Robinson",
    city_UK: "Brighton",
    Island: "Dominica 581A & B (Rosalie)",
    people_enslaved: 181,
    compensation: "£3,488",
    Money_today: "£567,009.30",
    latitude: 50.826041,
    longitude: -0.155044,


  },
  {
    id: 54,
    FirstName_LastName_UK: "John Matthews Boswell junior",
    city_UK: "Brighton",
    Island: "Guyana (British Guiana)",
    people_enslaved: " 392",
    compensation: "£20,820",
    Money_today: "No Data ",
    latitude: 50.8295,
    longitude: -0.1354,
  },
  {
    id: 55,
    FirstName_LastName_UK: "Sarah Mary Barker Lowdell (née Boswell)",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8295,
    longitude: -0.1344,
  },
  {
    id: 56,
    FirstName_LastName_UK: "Sarah Coppard Previously Boswell (née Saxby)",
    city_UK: "Brighton",
    Island: "barbados",
    people_enslaved: 79,
    compensation: "£2,444",
    Money_today: "£397,296.60",
    latitude: 50.8336,
    longitude: -0.1364,
  },
  {
    id: 57,
    FirstName_LastName_UK: "Alexander Purcell Anderson",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.827318,
    longitude: -0.141864,
  },
  {
    id: 58,
    FirstName_LastName_UK: "Dorothy Diana Yates (née Smellie)",
    city_UK: "Brighton",
    Island: "Jamaica Kingston 1943",
    people_enslaved: 10,
    compensation: "£169",
    Money_today: "£27,472.60",
    latitude: 50.820763,
    longitude: -0.142946,
  },
  {
    id: 59,
    FirstName_LastName_UK: "Elizabeth Stewart or Stuart formerly McLachlan",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8198343,
    longitude: -0.141884,
  },
  {
    id: 60,
    FirstName_LastName_UK: "George Baillie Previous owner (non-claimant)",
    city_UK: "Brighton",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8205,
    longitude: -0.141894,
  },
  {
    id: 61,
    FirstName_LastName_UK: "James Phyn",
    city_UK: "Brighton",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8218675,
    longitude: -0.141904,
  },
  {
    id: 62,
    FirstName_LastName_UK: "John Ingram senior",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.822841,
    longitude: -0.141914,
  },
  {
    id: 63,
    FirstName_LastName_UK: "Margaret Still Mavor",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8232106,
    longitude: -0.141924,
  },
  {
    id: 64,
    FirstName_LastName_UK: "Mary Ruthven (née Virgo)",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.823664,
    longitude: -0.141934,
  },
  {
    id: 65,
    FirstName_LastName_UK: "Nathaniel Bogle French senior",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.824,
    longitude: -0.141944,
  },
  {
    id: 66,
    FirstName_LastName_UK: "Robert Alexander Lambert RN",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8244792,
    longitude: -0.141954,
  },
  {
    id: 67,
    FirstName_LastName_UK: "Robert Home Gordon",
    city_UK: "Brighton",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.8247807,
    longitude: -0.141964,
  },
  {
    id: 68,
    FirstName_LastName_UK: "Susanna Harriot Gordon (née Hope)",
    city_UK: "Brighton",
    Island: "Jamaica St Ann 498 (Home Castle Estate)",
    people_enslaved: 798,
    compensation: "£15,945.00",
    Money_today: "£2,592,019.20",
    latitude: 50.826,
    longitude: -0.141974,
  },
  {
    id: 69,
    FirstName_LastName_UK: "Thomas Nevill",
    city_UK: "Brighton",
    Island: "No data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.826041,
    longitude: -0.141974,
  },
  {
    id: 70,
    FirstName_LastName_UK: "Thomas Scott Reignolds",
    city_UK: "Brighton",
    Island: "Jamaica St Thomas-in-the-Vale 294 (Hyde Estate)",
    people_enslaved: 147,
    compensation: "£2,660.00",
    Money_today: "£432,409.60",
    latitude: 50.8295,
    longitude: -0.141984,
  },
  {
    id: 71,
    FirstName_LastName_UK: "Robert Allen",
    city_UK: "Barcombe",
    Island: "Barbados",
    people_enslaved: 123,
    compensation: "£1,259",
    Money_today: "£204,663.00",
    latitude: 50.9325232,
    longitude: 0.0298005,
  },
  {
    id: 72,
    FirstName_LastName_UK: "Louise Scarlett",
    city_UK: "2 Lansdowne Terrace (partial)",
    Island: "Jamaica",
    Island: "East Lewes",
    people_enslaved: 35,
    compensation: "£558",
    Money_today: "£90,708.50",
    latitude: 50.8746139,
    longitude: 0.0051153,
  },
  {
    id: 73,
    FirstName_LastName_UK: "Richard Watt Walker",
    city_UK: "Pease Pottage, Crawley",
    Island: "Jamaica",
    people_enslaved: 189,
    compensation: "£3,421",
    Money_today: "£556,117.80",
    latitude: 51.072791,
    longitude: -0.200333,
  },
  {
    id: 74,
    FirstName_LastName_UK: "Samuel Jeffries",
    city_UK: "Pixton House, Forest Row",
    Island: "Jamaica",
    people_enslaved: 187,
    compensation: "£4,059",
    Money_today: "£659,831.00",
    latitude: 51.100045,
    longitude: 0.051609,
  },
  {
    id: 75,
    FirstName_LastName_UK: "Henry Shirley III",
    city_UK: "Pippinford Lodge (Manor)",
    Island: "Jamaica",
    people_enslaved: 64,
    compensation: "£1286",
    Money_today: "£209,052.20",
    latitude: 51.057329,
    longitude: 0.054371,
  },
  {
    id: 76,
    FirstName_LastName_UK: "Jonathan Worrell",
    city_UK: "Frampost",
    Island: "Barbados",
    people_enslaved: 141,
    compensation: "£3,030",
    Money_today: "£492,556.80",
    latitude: 51.109592,
    longitude: -0.016494,
  },
  {
    id: 77,
    FirstName_LastName_UK: "Anne Dalzell Thomson",
    city_UK: "Albourne",
    Island: "St Kitts",
    people_enslaved: 103,
    compensation: "£1,488",
    Money_today: "£241,889.30",
    latitude: 50.929866,
    longitude: -0.219119,
  },
  {
    id: 78,
    FirstName_LastName_UK: "George Elliott Clarke",
    city_UK: "Frampost",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 51.109776,
    longitude: -0.017747,
  },
  {
    id: 79,
    FirstName_LastName_UK: "John Trayton Fuller",
    city_UK: "East Grinstead",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 51.132218,
    longitude: -0.011766,
  },
  {
    id: 80,
    FirstName_LastName_UK: "Abraham Cumberbatch Sober",
    city_UK: "Lewes",
    Island: "No Data",
    people_enslaved: "No Data ",
    compensation: "No Data ",
    Money_today: "No Data ",
    latitude: 50.874614,
    longitude: 0.005115,
  },
  {
    id: 81,
    FirstName_LastName_UK: "Charles McGarel",
    city_UK: "Brighton",
    Island: "British Guiana - Demerara",
    people_enslaved: "1373",
    compensation: "£71,282",
    Investment: "£4000 London and Brighton Railway",
    Money_today: "c. £8.6 million",
    latitude: 50.82881504411202,
    longitude: -0.14112613291161816,
  },
  {
    id: 82,
    FirstName_LastName_UK: "Elizabeth Mary Alleyne (widow of John Alleyne?)",
    city_UK: "Brighton",
    Island: "Barbados",
    people_enslaved: "",
    compensation: "",
    Investment: "",
    Money_today: "",
    latitude: 50.827522,
    longitude: -0.157937,
  },
  {
    id: 83,
    FirstName_LastName_UK: "Orford Gordon",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: "",
    compensation: "",
    Investment: "",
    Money_today: "",
    latitude: 50.82327557568452,
    longitude: -0.15700204884069288,
  },
  {
    id: 84,
    FirstName_LastName_UK: "Langford Lovell Hodge",
    city_UK: "Brighton",
    Island: "Antigua",
    people_enslaved: "",
    compensation: "",
    Investment: "",
    Money_today: "",
    latitude: 50.82524,
    longitude: -0.173182,
  },
  {
    id: 85,
    FirstName_LastName_UK: "Richard Lane",
    city_UK: "Brighton",
    Island: "Barbados",
    people_enslaved: "",
    compensation: "",
    Investment: "",
    Money_today: "",
    latitude: 50.824845,
    longitude: -0.159485,
  },
  {
    id: 86,
    FirstName_LastName_UK: "Jacob Montefiore",
    city_UK: "Brighton",
    Island: "Barbados",
    people_enslaved: "",
    compensation: "",
    Investment: "",
    Money_today: "",
    latitude: 50.82411,
    longitude: -0.159181,
  },
  {
    id: 87,
    FirstName_LastName_UK: "Elizabeth Murray (brother John Murray)",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: "",
    compensation: "",
    Investment: "",
    Money_today: "",
    latitude: 50.82378625831206,
    longitude:  -0.1609768023233699,
  },
  {
    id: 88,
    FirstName_LastName_UK: "John Watson",
    city_UK: "Brighton",
    Island: "Jamaica",
    people_enslaved: "",
    compensation: "",
    Investment: "",
    Money_today: "",
    latitude: 50.828496,
    longitude: -0.156107,
  },
]

let saved = {
  "8": {
    "id": 8,
    "FirstName_LastName_UK": "Kenrick Francis Saunders",
    "city_UK": "Brighton",
    "Island": "Barbados 4601",
    "people_enslaved": 164,
    "compensation": "£3,577.00",
    "Money_today": "£581,477.10",
    "latitude": 50.827716,
    "longitude": -0.141673,
    "color": "red"
  },
  "9": {
    "id": 9,
    "FirstName_LastName_UK": "Ann Napier (née Stirling)",
    "city_UK": "Brighton",
    "Island": "Tobago 5",
    "people_enslaved": 123,
    "compensation": "£2,576.00",
    "Money_today": "£418,754.60",
    "latitude": 50.826033,
    "longitude": -0.142296,
    "color": "red"
  },
  "13": {
    "id": 13,
    "FirstName_LastName_UK": "Catherine Wratislaw (née Barry)",
    "city_UK": "Brighton",
    "Island": "British Guiana 2492",
    "people_enslaved": 108,
    "compensation": "£ 5,813",
    "Money_today": "£944,961.30",
    "latitude": 50.827995,
    "longitude": -0.141479,
    "color": "red"
  },
  "14": {
    "id": 14,
    "FirstName_LastName_UK": "Cecilia Blake ",
    "city_UK": "Brighton",
    "Island": "St Vincent 470",
    "people_enslaved": 188,
    "compensation": "£5,052",
    "Money_today": "£821,253.10",
    "latitude": 50.822158,
    "longitude": -0.144199,
    "color": "red"
  },
  "17": {
    "id": 17,
    "FirstName_LastName_UK": "Edward Hoare",
    "city_UK": "Brighton",
    "Island": "Jamaica Manchester 338 (Mile Gully)",
    "people_enslaved": 998,
    "compensation": "£19,400",
    "Money_today": "£3,153,664.00",
    "latitude": 50.8242199,
    "longitude": -0.14481
  },
  "19": {
    "id": 19,
    "FirstName_LastName_UK": "George Monk",
    "city_UK": "Brighton",
    "Island": "Antigua 15",
    "people_enslaved": 27,
    "compensation": "£400",
    "Money_today": "£65,024.00",
    "latitude": 50.822681,
    "longitude": -0.14408
  },
  "22": {
    "id": 22,
    "FirstName_LastName_UK": "Henry Yarburgh Everitt",
    "city_UK": "Brighton",
    "Island": "Antigua 487A [&B] (Sion Hill)",
    "people_enslaved": 176,
    "compensation": "£2,734",
    "Money_today": "£444,439.00",
    "latitude": 50.824996,
    "longitude": -0.14285
  },
  "24": {
    "id": 24,
    "FirstName_LastName_UK": "John Brome",
    "city_UK": "Brighton",
    "Island": "Barbados 4800 (Bromefield)",
    "people_enslaved": 285,
    "compensation": "£6,542",
    "Money_today": "£1,063,467.50",
    "latitude": 50.8249099,
    "longitude": -0.159461
  },
  "27": {
    "id": 27,
    "FirstName_LastName_UK": "John Henry Noding ",
    "city_UK": "Brighton",
    "Island": "Tobago",
    "people_enslaved": 118,
    "compensation": "£2,800",
    "Money_today": "£455,168.00",
    "latitude": 50.825742,
    "longitude": -0.142434
  },
  "29": {
    "id": 29,
    "FirstName_LastName_UK": "John Roach Bovell and John Matthews Boswell junior",
    "city_UK": "Brighton",
    "Island": "British Guiana",
    "people_enslaved": 392,
    "compensation": "£20,820.00",
    "Money_today": "£3,384,499.20",
    "latitude": 50.828287,
    "longitude": -0.141603
  },
  "30": {
    "id": 30,
    "FirstName_LastName_UK": "Louisa Barry",
    "city_UK": "Brighton",
    "Island": "British Guiana",
    "people_enslaved": 108,
    "compensation": "£5,813.00",
    "Money_today": "£944,961.30",
    "latitude": 50.828653,
    "longitude": -0.141294
  },
  "32": {
    "id": 32,
    "FirstName_LastName_UK": "Mary Johnson (née Francis)",
    "city_UK": "Brighton",
    "Island": "Antigua",
    "people_enslaved": 264,
    "compensation": "£3,461.00",
    "Money_today": "£562,620.20",
    "latitude": 50.824364,
    "longitude": -0.143171
  },
  "35": {
    "id": 35,
    "FirstName_LastName_UK": "Richard Alexander Oswald",
    "city_UK": "Brighton",
    "Island": "Jamaica",
    "people_enslaved": 297,
    "compensation": "£5,445",
    "Money_today": "£885,139.20",
    "latitude": 50.826829,
    "longitude": -0.141971
  },
  "37": {
    "id": 37,
    "FirstName_LastName_UK": "Richard Silvester Cahill",
    "city_UK": "Brighton",
    "Island": "Trinidad",
    "people_enslaved": 131,
    "compensation": "£3,769",
    "Money_today": "£612,688.60",
    "latitude": 50.825402,
    "longitude": -0.142655
  },
  "38": {
    "id": 38,
    "FirstName_LastName_UK": "Robert Home Gordon",
    "city_UK": "Brighton",
    "Island": "Jamaica",
    "people_enslaved": "No Data ",
    "compensation": "No Data ",
    "Money_today": "No Data ",
    "latitude": 50.827318,
    "longitude": -0.141854
  },
  "39": {
    "id": 39,
    "FirstName_LastName_UK": "Samuel Anderson and Anna Rebecca Anderson (née Ruthven)",
    "city_UK": "Brighton",
    "Island": "Jamaica",
    "people_enslaved": 252,
    "compensation": "£4,866",
    "Money_today": "£791,017.00",
    "latitude": 50.8247482,
    "longitude": -0.1531025,
    "image": "/static/media/39.c29add1d60a22b541060.png",
    "alt": "id :39"
  },
  "41": {
    "id": 41,
    "FirstName_LastName_UK": "Thomson Hankey ",
    "city_UK": "Brighton",
    "Island": "Grenada 445 (Tempe Estate)",
    "people_enslaved": 1919,
    "compensation": "£49,706",
    "Money_today": "£8,080,207.40",
    "latitude": 50.8239702,
    "longitude": -0.1584867,
    "image": "/static/media/41.8a69eeb9a7b9f0dff84a.png",
    "alt": "id :41"
  },
  "47": {
    "id": 47,
    "FirstName_LastName_UK": "William Augustus Parker",
    "city_UK": "Brighton",
    "Island": "British Guiana 1475",
    "people_enslaved": 88,
    "compensation": "£4,500",
    "Money_today": "£73,1520.00",
    "latitude": 50.8232106,
    "longitude": -0.1531099,
    "image": "/static/media/47.3c781dd765ff952c366c.png",
    "alt": "id :47",
    "preview": "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/WilliamAugustusParker.jpg",
    "trackingSrc": "https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/WilliamAugustusParker"
  },
  "48": {
    "id": 48,
    "FirstName_LastName_UK": "William King Mitchell",
    "city_UK": "Western Lodge, (sillwood place )",
    "Island": "Antigua 38 (Marble Hill) ",
    "people_enslaved": 199,
    "compensation": "£2,482",
    "Money_today": "£403,473.90",
    "latitude": 50.823664,
    "longitude": -0.153362
  },
  "57": {
    "id": 57,
    "FirstName_LastName_UK": "Alexander Purcell Anderson",
    "city_UK": "Brighton",
    "Island": "Brighton",
    "people_enslaved": "No Data ",
    "compensation": "No Data ",
    "Money_today": "No Data ",
    "latitude": 50.827318,
    "longitude": -0.141864
  },
  "68": {
    "id": 68,
    "FirstName_LastName_UK": "Susanna Harriot Gordon (née Hope)",
    "city_UK": "Brighton",
    "Island": "Jamaica St Ann 498 (Home Castle Estate)",
    "people_enslaved": 798,
    "compensation": "£15,945.00",
    "Money_today": "£2,592,019.20",
    "latitude": 50.826,
    "longitude": -0.141974
  },
  "70": {
    "id": 70,
    "FirstName_LastName_UK": "Thomas Scott Reignolds",
    "city_UK": "Brighton",
    "Island": "Jamaica St Thomas-in-the-Vale 294 (Hyde Estate)",
    "people_enslaved": 147,
    "compensation": "£2,660.00",
    "Money_today": "£432,409.60",
    "latitude": 50.8295,
    "longitude": -0.141984
  },
  "81": {
    "id": 81,
    "FirstName_LastName_UK": "Charles McGarel",
    "city_UK": "Brighton",
    "Island": "British Guiana - Demerara",
    "people_enslaved": "1373",
    "compensation": "£71,282",
    "Investment": "£4000 London and Brighton Railway",
    "Money_today": "c. £8.6 million",
    "latitude": 50.82881504411202,
    "longitude": -0.14112613291161816
  }
}

console.log("SAVED LENGTH : " + Object.keys(locationData).filter( key => locationData[key].city_UK.toLowerCase().includes("brighton") &&  locationData[key].Island!= null&&  !locationData[key].Island.toLowerCase().includes("no data")).length)