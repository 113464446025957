import React from "react";
import { Link } from "react-router-dom";

// import icons from icons
import Icons from "../img/Icons";

import "./Navbar.css";
import { getUserinfo } from "../../firebase";
import { useState } from "react";
import {browserHistory} from 'react-router';

const Navbar = () => {


  // const [getUpdate, update]  =  useState(true);




  let [onHome, setOnHome]  =  useState(window.location.pathname == "/");

  
  if ( window.location.href.includes("disallowed")){
    return;
  }

  return (
    <nav id="navbar" className={"primary-navbar " }>
      <nav-menu>
        {/* <img class="profile-pic" src={getUserinfo().photoURL}></img> */}
        <nav-option>

        </nav-option>

       
      </nav-menu>
      
        <div className="Primary__navbar-link">
          {/* <Link to="/">
            <img className="Nav__icon" src={Icons.iconHome} alt="Home icon" />
          </Link> */}

          <Link to="/about">
            <img
              className="Nav__icon"
              src={Icons.iconPerson}
              alt="Person icon"
            />
          </Link>

          <Link to="/mapData">
            <img id="main-nav-button" className="Nav__icon" src={Icons.iconMap} alt="Map icon" />
          </Link>

          {/* <Link to="/locations">
            <img className="Nav__icon" src={Icons.iconEye} alt="Eye icon" />
          </Link> */

          /* <Link to="/Gallery">
            <img
              className="Nav__icon"
              src={Icons.iconCircles}
              alt="circles icon"
                 </Link> 
  /> */}
        
          <Link to="/contact">
            <img
              className="Nav__icon"
              src={Icons.iconEmail}
              alt="Gallery icon"
            />
          </Link>
        </div>
      


    </nav>
  );
};

export default Navbar;
