import * as React from "react";
import "mapbox-gl/dist/mapbox-gl.css";
// import mapboxgl from "mapbox-gl";
import Map, {
  Marker,
  GeolocateControl,
  NavigationControl,
  Popup,  
} from "react-map-gl";
// import { THREE } from "aframe";

// import * as ArJS from "ar.js"
// import * as aframe from "aframe/"
// import GoringHall_01 from "../../img/GoringHall_01.png";
import "./AR.css";
import { locationData ,arData,audioData} from "../Map/locationData";
import {navigatePage } from "../../../firebase"
import {antigua } from "../../../Poster Final/antigua(1).png"


// let img = require(finalSrc)
// console.log(img);

// loadImage = imageName => (assets(`./${imageName}`).default);
// <img src={loadImage("someimage.png")} alt="" />

// import img1 from finalSrc;


// import { getLCP } from "web-vitals";
// import "https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar-nft.js";


// let tracking =  require("./testTrack.fset3").default;
// let tracking1 =  require("./testTrack.fset").default;
// let tracking2 =  require("./testTrack.iset").default;



let showingAR = true;


  let cameraEl;



  let openSound = new Audio("https://raw.githubusercontent.com/AR-Hive/ImageHosting/main/audio/drop_004.ogg");



  const clamp = (num,min,max) => Math.min(Math.max(num,min),max)
  const AFRAME = window.AFRAME;
  
  let prevRot = ""
  let loadRot = false
  let markerFound = false

  let foundRotation = {x:0,y:0,z:0}

  




  let toggled = false
  function focusInfo(){
    let locPopUp = document.getElementById("locPopUp")

    enableTransition(300);

    if (!toggled) {
      locPopUp.classList.add("focus-pop-up")
    }
    else{
      locPopUp.classList.remove("focus-pop-up")
    }
    toggled = !toggled;
  
  }
  function unfocusInfo(){
    if (toggled){
      focusInfo();
    }
  }

  function enableTransition(num){
    let locPopUp = document.getElementById("locPopUp")

    locPopUp.classList.add("focusTransition")

    setTimeout(function(){
      locPopUp.classList.remove("focusTransition")
      }, num);
  }

  function setImagePreview(showing){
    
    let prevImage = document.getElementById("preview-Image")
    if (showing){

      prevImage.classList.remove("previewImageFound")
    }else{
      prevImage.classList.add("previewImageFound")
    }

  }




 
let rotation = {x:0,y:0,z:0} ;
let position = {x:0,y:0,z:0} 
  
function handleOrientation(event) {

    const alphaChange = event.rotationRate.alpha/50;
    const betaChange = event.rotationRate.beta/50;
    const gammachange = event.rotationRate.gamma/50;

    const xChange = event.acceleration.x*10;
    const yChange = event.acceleration.y*10;
    const zChange = event.acceleration.z*10;

    rotation.x += alphaChange;
    rotation.y += betaChange;
    rotation.z += gammachange;

    position.x -= xChange;
    position.y -= yChange;
    position.z += zChange;

    
    // alert("ORIENT")
    console.log("EVENT")
    console.log(event)
    // Do stuff...



    
    let nowRot = rotation;


    if ((prevRot != JSON.stringify(nowRot) || loadRot)) {
      

      // let locPopUp = document.getElementById("locPopUp")

      // let translateX = (clamp(nowRot.y,-90,90) /90) * window.innerWidth
      // let translateY= (clamp(nowRot.x,-90,90) /90) * window.innerHeight
      // locPopUp.style.transform = `translateX(${translateX }px) translateY(${translateY }px) rotateX(${nowRot.x}deg) rotateY(${-nowRot.y}deg)`
      // prevRot = JSON.stringify(nowRot)

          let locPopUp = document.getElementById("locPopUp")

          if (locPopUp){
        console.log("CAMERA ROT |  " + JSON.stringify(nowRot))
        console.log("FOUND ROT | " + JSON.stringify(foundRotation))

        let rotX= nowRot.x - foundRotation.x
        let rotY = nowRot.y - foundRotation.y
        let rotZ = nowRot.z - foundRotation.z;
        

        console.log("FINAL ROT | " + rotX + " " + rotY)
        let translateX = (clamp(rotY,-90,90) /90) * window.innerWidth
        let translateY= (clamp(rotX,-90,90) /90) * window.innerHeight 
        locPopUp.style.transform = `translateX(${translateX }px) translateY(${translateY }px) rotateX(${rotX}deg) rotateY(${-rotY}deg) `
      
      
        prevRot = JSON.stringify(nowRot)
        loadRot = false;
    }
    }


}

export async function getGyro() {
  console.log("IN GYRO")
  if (typeof DeviceMotionEvent.requestPermission === 'function') {
    console.log("IN HERE ")
    // Handle iOS 13+ devices.
    await DeviceMotionEvent.requestPermission()
      .then((state) => {
        if (state === 'granted') {

          // alert("DEVICE MOTION GRANT")
          window.addEventListener('devicemotion', handleOrientation);
        } else {
          console.error('Request to access the orientation was rejected');
        }
      })
      .catch(console.error);
  } else {

    // Handle regular non iOS 13+ devices.
    // alert("DEVICE MOTION AUTO")
    window.addEventListener('devicemotion', handleOrientation);
  }
}

window.onload = function(){
  getGyro()
}

export const AR = () => {


  


let targetLocID = 0
function checkLocID(Obj) {
    return Obj.id == targetLocID;
}
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

let items= window.location.pathname.split("/")
let lastItem = items[items.length - 1]
console.log("ID AT",lastItem)
let locationAtInfo = {}
if (lastItem.toLocaleLowerCase() != "ar"){

    targetLocID = parseInt(lastItem)
    locationAtInfo = locationData.find(checkLocID)
    console.log("AR LOC");
    console.log(locationAtInfo)

}



// let host = "../../../Poster Final"
let host = "../Ar/Poster Final/"
 
let arDataFound;

console.log("HERE NOW",locationAtInfo)
for( let key in arData){

  console.log(key)

 if (locationAtInfo.Island.toLowerCase().includes(arData[key].name)){
   
   arDataFound = arData[key]

 }

}

console.log("FIND AR DATA ")
console.log(locationAtInfo.Island,arDataFound)


// let back = arData["back"].arSrc
let images = [arData[0].arSrc].concat(arDataFound.arSrc[getRandomInt(arDataFound.arSrc.length)]);

for (let i=0;i<3;i++) {



}
console.log("IM HERE",  images)


  function updateAR(value){
  
    showingAR = value;
    if (document.getElementById("arjs-video") != null){
      if (showingAR){
      document.getElementById("arjs-video").classList.add("show");
      }
      else{
        document.getElementById("arjs-video").classList.remove("show");
      }
    }
  
    if (document.getElementById("ar-overlay") != null){
      if (showingAR){
      document.getElementById("ar-overlay").classList.add("show");
      }
      else{
        document.getElementById("ar-overlay").classList.remove("show");
      }
    }
    if (document.getElementById("ArPopName") != null){
      document.getElementById("ArPopName").innerHTML = `NOW AT ${locationAtInfo.FirstName_LastName_UK}`
  
    }
    console.log("LOCA " + locationAtInfo.name)
  
   
  
  
  ;
  };
  
  

  setTimeout(function(){
  
    updateAR(true)
  },2000)

  
  delete AFRAME.components['locationnft']

  
  window.addEventListener("arjs-nft-loaded",function(){
    console.log("LOADED")
  })
 
  AFRAME.registerComponent('locationnft', {
    init: function () {

   

        console.log("LOC SHOWING AR")
        // alert("REG COMP");
        var marker = this.el;

       


        // document.querySelector("#arpopup").setAttribute('gps-entity-place', {
        //   latitude: 0,
        //   longitude: 0
        //  });
        //  document.querySelector("#arpopup").components["gps-entity-place"]._updatePosition();

        marker.addEventListener('markerFound', function () {

     
          if (markerFound) return
          markerFound = true;


          // cameraEl = this.el.sceneEl.camera.el;

          
          enableTransition(300);
          let locPopUp = document.getElementById("locPopUp")
          locPopUp.classList.remove("hidePopUp")
          foundRotation = { x: rotation.x, y: rotation.y,z : rotation.z};
          // setImagePreview(false)

          let previewImg = document.getElementById("preview-Image")
          previewImg.classList.add("hidePopUp")

          // foundRotation = cameraEl.getAttribute('rotation');
          // loadRot = true
          // markerFound = true;

  
          // let rotate =  cameraEl.getAttribute('rotation');
          // // // cameraEl.setAttribute('rotation',{x:-rotate.x,y:-rotate.y,z:-rotate.z});

          // // alert("ROT AFTER " +  JSON.stringify(cameraEl.getAttribute('rotation')))

          var popup = document.querySelector("#arpopup")
          
          openSound.play()
     

        }.bind(this));
        // camera.addEventListener('gps-camera-update-positon', function () {

       
        //     // alert("TRACK FOUND")
        // }.bind(this));

  marker.addEventListener('markerLost', function() {
      // this.sound.pause();
      // this.sound.currentTime = 0;
  }.bind(this));
      },
    // tick:function(){
    //   console.log("hello")
    // }
});

  

  // const projectRootDirectory = require('project-root-directory')

  // console.log("ROOT "  + projectRootDirectory)


  const [showPopup, setShowPopup] = React.useState(false);
  // const [popupColor, setPopupColor] = React.useState("");


  const [isActive, setIsActive] = React.useState(false);

  // const [locationInfo, setLocationInfo] = React.useState({});
  const [vantageFound, setVantageFound] = React.useState(true);

  const data = locationData;


  var prevlocation = "";

  

  function setLocationInfo(info){
    locationAtInfo = info

    let prevImage = document.getElementById("preview-Image")
    prevImage.src = locationAtInfo.preview;
  }

  // const el = document.querySelector('#arjs-video');
  // el.classList.add("hidden");



  console.log("HELLo")

  ;








async   function getVideo() {

  const video = document.querySelector('.cameraUnderlay');
  video.setAttribute('autoplay', '');
  video.setAttribute('muted', '');
  video.setAttribute('playsinline', '')

  const constraints = {
    audio: false,
    video: {
      facingMode: 'environment',
      // width: { ideal: 1800 }, 
      // height: { ideal: 900 },
    }
  }

  navigator.mediaDevices.getUserMedia(constraints)
    .then(async function(localMediaStream) {
      console.log(localMediaStream);
    
//  DEPRECIATION : 
//       The following has been depreceated by major browsers as of Chrome and Firefox.
//       video.src = window.URL.createObjectURL(localMediaStream);
//       Please refer to these:
//       Deprecated  - https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
//       Newer Syntax - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/srcObject
      console.dir(video);
      if ('srcObject' in video) {
        video.srcObject = localMediaStream;
      } else {
        video.src = URL.createObjectURL(localMediaStream);
      }
      // video.src = window.URL.createObjectURL(localMediaStream);
      video.play();


      let stream = await navigator.mediaDevices.getUserMedia(constraints);

      let stream_settings = stream.getVideoTracks()[0].getSettings();
  
      // actual width & height of the camera video
      let stream_width = stream_settings.width;
      let stream_height = stream_settings.height;

      let ratio = stream_width/stream_height;
      video.style.width  = ratio*100 + "vh";
      video.style.height  = 100 +  " vh"
      
    })
    .catch(err => {
      console.error(`OH NO!!!!`, err);
    });
  }

  
 
  


  React.useLayoutEffect(() => {
    let elements = document.getElementsByClassName("ar-layer");

    let num = 0
     Array.from(elements).forEach(function (element) {
       element.style.transform  = `translateZ(${num*50}px)`;
       num++;
     });

     let name = document.getElementById("ar-text-layer");
    //  name.style.transform  = `translateZ(${num*50}px)`;


    getVideo()

    
 }, []);


  return (
    
    <main className="main__body-AR">



      { locationAtInfo.trackingSrc != null ? 
          <a-scene
            id = "ar-overlay"
            vr-mode-ui="enabled: false;"
            renderer="logarithmicDepthBuffer: true; precision: medium;"
            embedded
            arjs="trackingMethod: best; sourceType: webcam;debugUIEnabled: false;"
          
            // onClick={unfocusInfo}
          >
            <a-nft
            id="trackingModel" 
              locationnft
              type="nft"
              url={locationAtInfo.trackingSrc}
              smooth="true"
              smoothCount="10"
              smoothTolerance=".01"
              smoothThreshol
              d="5"
            >
            </a-nft>

            <camera-info-back>      
              <preview-container>
                  <img  id="preview-Image" src={locationAtInfo.preview}/>
              </preview-container>

              </camera-info-back>

          </a-scene >


       : ""}

      <camera-container>    <video class="cameraUnderlay"></video></camera-container>

      <location-back >
          <location-pop-up class= {locationAtInfo.trackingSrc != null ? "hidePopUp" :"" } id="locPopUp" onClick={getGyro}>
          <ar-content>
      <img class="ar-layer" src={images[0]}></img>
      <img class="ar-layer" src={images[1]}></img>
      <img class="ar-layer" src={images[2]}></img>

        { "id_" +locationAtInfo.id in audioData ?    
          <audio controls autoplay class="ar-layer">
              <source src={audioData["id_" +locationAtInfo.id ]} type="audio/mpeg"/>
      </audio>  
      : ""}
 
    </ar-content>

             <pop-up-shadow></pop-up-shadow> 
      <location-name class="ar-layer"> {locationAtInfo.FirstName_LastName_UK}</location-name>
     
      </location-pop-up>
      </location-back>
      <back-button onClick={()=>{navigatePage("/mapData")}}>
        back
      </back-button>

      { locationAtInfo.trackingSrc != null ? 
            <div class="arjs-loader">
          <div>Loading...</div>
        </div> : ""
      }

    </main>
    
  );
};
