import iphone1 from "../About/iphone1.png"
import iphone2 from "../About/iphone2.png"

import android1 from "../About/android1.png"
import android2 from "../About/android2.png"




export const AccordionData = [
  {
    
    title: " How does it work?",
    content:
    <span> 
       <br/>
 <span class="section"> Explainer Video</span> <br/><br/>
<video controls class="tut-video" src={require("../About/Demo video.mp4")}>

</video>
<br/><br/>


    1. Wait for your location to be found <br/><br/>
    2. Select the location you want to visit on the map, and route will load.<br/><br/>
    3. Walk to the location.<br/><br/>
    4. When you have arrived on the street, a thumbnail will apprear<br/><br/>
    5. Press the thumbnail  to activate the augmented reality <br/><br/>
    6. The augmented reality will appear; including an image, text and audio<br/><br/>
    7. You can press the  play button to control the audio<br/><br/>
    8. To escape, press the back button to take you to the main map again.<br/><br/>


    </span>,
  },
  {
    title: "Set up",
    content: <span>
    Before you start the experience, give the following permissions: <br/><br/>
    1. Location Services  <br/><br/>
    2. Camera Permissions <br/><br/>

    <span class="section"> IPhone steps:</span> <br/><br/>

    Go to phone settings, then  privacy & security  <br/><br/>
    Go to your browser  safari/chrome/Firefox  etc, enable 'while use the app' <br/>
    <img class="tut-img" src={iphone1}></img>

    iPhone Camera settings <br/><br/>
    Go to phone settings <br/><br/>
    Go to your browser select safari/chrome/Firefox etc  <br/><br/>
    Go to Camera click allow  <br/><br/>

    <img class="tut-img" src={iphone2}></img>

    <span class="section"> Android steps:</span> <br/><br/>

    Android location services  <br/><br/>
    Go to settings <br/><br/>
    Go to apps tab <br/><br/>
    Click see all apps,  <br/><br/>
    search for safari/chrome/Firefox  etc. <br/><br/>
    Click on the camera click allow only while using the app, <br/><br/>

    <img class="tut-img" src={android1}></img>

    Android Camera settings  <br/><br/>
    Go back to the same browser settings <br/><br/>
    Click on location, and click allow only while using the app <br/><br/>

    <img class="tut-img" src={android2}></img>
    </span>
    ,
  },

  {
    title: "What is the project about?",
    content:   <span> 
This ground-breaking experience uses smartphone technology to overlay information around the city with digital artefacts, which help to reveal the hidden history of the city's involvement in the transatlantic slave trade.
By walking, and experiencing this difficult history in amongst the physical buildings of the city’s enslavers, the artist's hope is that audiences will contemplate our legacy by walking with the past directly overlaid onto parts of our local neighbourhoods.

<br/><br/>

This work is designed not just to highlight locations in the city, but most importantly to start to make connections with the plantations and people who had a significant financial influence on this city.
    </span>,
  },




  {
    title: "Where is the data from?",
    content: <span>
     The Slave Trade Act of 1807, took effect on May 1, officially known as "An Act for the Abolition of the Slave Trade," was a significant piece of legislation passed by Parliament. This act prohibited the slave trade in the British Empire. <br/><br/>
It is important to note that while the Slave Trade Act of 1807 prohibited the transatlantic slave trade, it did not abolish the practice of slavery itself. The act aimed to encourage other nations to follow suit and abolish their own slave trades. <br/><br/>
Although a significant act of parliament, it took another 26 years to effect the emancipation of the enslaved in the Slavery Abolition Act of 1833, marking the complete emancipation of enslaved individuals in most parts of the British colonies, freeing more than 800,000 enslaved Africans in the Caribbean and South Africa, Mauritius, as well as a small number in Canada.<br/><br/>
By the time of the Slavery Abolition Act of 1833 coming into force, the economy wasn’t as reliant on the triangular trade as it had been during its heyday in the early 1700s. These economic shifts and the combination of various efforts, including those of enslaved Africans, legal challenges, and the work of abolitionists, collectively contributed to the success of the abolitionist movement in Britain.<br/><br/>
The compensation payments to slave owners were made in 1835, as indicated in the records of the National Debt Office. This compensation scheme that was established for slave owners who lost their "property" as a result of this act was also established at that time, and the government paid out £20 million in bonds over 38 years. These records are the basis of this project.<br/><br/>
The records of the Slave Compensation Commission, which include the names, addresses, and plantation details of former slave owners, are available for research and study through resources such as the Legacies of British Slavery project.  <br/><br/>
    </span>,
  },
  {
    title: "How is the inflation calculated?",
    content: <span>
  This data story uses the CPI Inflation Calculator
    </span>,
  },
  {
    title: "What areas are covered?",
    content: <span>

In this research project the City of Brighton and Hove is covered by the data
    </span>,
  },
  {
    title: "How to get involved",
    content: <span>

Please send us your technical feedback using the link above, indicating if you would like us to follow up with you by leaving your email address.
    </span>,
  },
  {
    title: "Citing this work?",
    content: <span>
      This work remains the intellectual property of <br/> © Ar Hive {new Date().getFullYear() } Street Story v1 [Mobile webapp] 
      <br/><br/>
      To cite this work:<br/> © Ar Hive {new Date().getFullYear() }

  </span>,
  },
];
